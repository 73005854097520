import { Flex } from "@common/styles";
import styled, { Interpolation } from "styled-components";
type Props = {
  type?: string;
  labelText?: any;
  labelIcon?: any;
  valueText?: React.ReactNode;
  tagline?: any;
  taglineIcon?: any;
  styles?: Interpolation<React.CSSProperties>;
};
const LabelValuePair: React.FC<Props> = ({
  type,
  labelText,
  labelIcon,
  valueText,
  tagline,
  taglineIcon,
  styles
}) => {
  return <Wrapper $type={type} $styles={styles} data-sentry-element="Wrapper" data-sentry-component="LabelValuePair" data-sentry-source-file="LabelValuePair.tsx">
			<Flex $alignItems="center" $gap="4px" data-sentry-element="Flex" data-sentry-source-file="LabelValuePair.tsx">
				{labelText || null}
				{labelIcon || null}
			</Flex>
			<Flex $alignItems="baseline" $gap="4px" data-sentry-element="Flex" data-sentry-source-file="LabelValuePair.tsx">
				{valueText || null}
				<Flex $alignItems="center" $gap="4px" data-sentry-element="Flex" data-sentry-source-file="LabelValuePair.tsx">
					{tagline || null}
					{taglineIcon || null}
				</Flex>
			</Flex>
		</Wrapper>;
};
export default LabelValuePair;
const Wrapper = styled.div<{
  $type?: string;
  $styles?: Interpolation<React.CSSProperties>;
}>`
	display: flex;
	flex-direction: ${({
  $type
}) => $type === "inverted" ? "column-reverse" : "column"};

	${({
  $styles
}) => $styles}
`;