import { minDesktopCss } from "@common/styles/media";
import { LabelSemiBoldText } from "@common/styles/typographies";
import styled, { Interpolation, css } from "styled-components";
type LabelProps = {
  type?: string;
  text?: string;
  bgColor?: string;
  hoverBgColor?: string;
  mobTextColor?: string;
  webTextColor?: string;
  hoverWebTextColor?: string;
  styles?: Interpolation<React.CSSProperties>;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  id?: string;
};
const Label: React.FC<LabelProps> = ({
  type,
  text,
  bgColor,
  hoverBgColor,
  mobTextColor,
  webTextColor,
  hoverWebTextColor,
  styles,
  iconLeft,
  iconRight,
  onClick = () => {},
  id
}) => {
  return <Wrapper $type={type} $bgColor={bgColor} $hoverBgColor={hoverBgColor} $hoverWebTextColor={hoverWebTextColor} $styles={styles} onClick={onClick} id={id} data-sentry-element="Wrapper" data-sentry-component="Label" data-sentry-source-file="Label.tsx">
			{iconLeft}
			<LabelSemiBoldText id={id} $mobColor={mobTextColor} $webColor={webTextColor} data-sentry-element="LabelSemiBoldText" data-sentry-source-file="Label.tsx">
				{text}
			</LabelSemiBoldText>
			{iconRight}
		</Wrapper>;
};
export default Label;
const Wrapper = styled.span<{
  $type?: string;
  $bgColor?: string;
  $styles?: Interpolation<React.CSSProperties>;
  $hoverBgColor?: string;
  $hoverWebTextColor?: string;
}>`
	padding: 2px 12px;
	border-radius: 4px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 4px;

	${({
  $hoverBgColor,
  $hoverWebTextColor,
  $styles,
  theme,
  $type,
  $bgColor
}) => css`
		background-color: ${$type === "success" ? theme.colors.bg.feedback.success : $bgColor || ""};

		${$styles}

		${minDesktopCss(css`
			transition: background-color 0.2s ease-out;

			${LabelSemiBoldText} {
				transition: color 0.2s ease-out;
			}

			&:hover {
				${LabelSemiBoldText} {
					color: ${$hoverWebTextColor || ""};
				}

				background-color: ${$hoverBgColor || ""};
			}
		`)}
	`}
`;