export const getCommaSeparatedInrString = (
	number?: number,
	noOfDecimals = 0,
): string | number => {
	try {
		const num = Number(number) * 1
		return num.toLocaleString("en-IN", {
			maximumFractionDigits: noOfDecimals,
			minimumFractionDigits: noOfDecimals,
			style: "currency",
			currency: "INR",
		})
	} catch (error) {
		console.error(error)
		return number || 0
	}
}

export const getNumberFromCurrencyString = (num: string): number => {
	return parseFloat(num.replace(/[^\d.]*/g, ""))
}

// converts number with prefix rupee and postfix in K, L, Cr
export const formatNumberIndianSystem = (
	number: number,
	hasRounding: boolean = true,
): string => {
	if (number < 1000) {
		return number.toFixed(2).toString()
	}

	// value: A numerical threshold. 1e -> 10 and 7,5,3 denotes the power.
	const suffixes = [
		{ value: 1e7, symbol: "Cr" },
		{ value: 1e5, symbol: "L" },
		{ value: 1e3, symbol: "k" },
	]

	const suffix = suffixes.find(items => number >= items.value)

	if (suffix) {
		const baseValue = Math.floor(number / suffix.value)
		const remainder = number % suffix.value
		const isExact = remainder === 0
		const isInRange = remainder >= 0 && remainder < suffix.value

		if (isExact || !hasRounding) {
			return `${getCommaSeparatedInrString(baseValue)}${suffix.symbol}`
		}
		if (isInRange && hasRounding) {
			return `${getCommaSeparatedInrString(baseValue)}${suffix.symbol}+`
		}
	}

	return getCommaSeparatedInrString(number)?.toString()
}

export const removeTrailingSlashFromURL = (str: string): string => {
	if (str.endsWith("/")) {
		return str.slice(0, -1)
	}
	return str
}

export const capitalizeFirstLetter = (string: string) => {
	try {
		return string
			.toLowerCase()
			.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
	} catch (error) {
		console.error(error)
		return string
	}
}

export const roundDownToNearestInteger = (number: string): number => {
	const numericValue = parseFloat(number)
	return Math.floor(numericValue)
}

export const roundDownToNearestTargetGiven = (
	number: number,
	target: number,
): number => {
	return Math.floor(number / target) * target
}

export const roundToNDigits = (number: number, noOfDigits: number): number => {
	switch (noOfDigits) {
		case 3:
			return roundDownToNearestTargetGiven(number, 10)
		case 4:
			return roundDownToNearestTargetGiven(number, 100)
		case 5:
			return roundDownToNearestTargetGiven(number, 1000)
		default:
			return number
	}
}

export const indianNumberWithNotationPostFix = ({
	number,
	isCurrency = true,
	shortPostFix = false,
}: {
	number: number
	isCurrency?: boolean
	shortPostFix?: boolean
}): string => {
	if (number < 1000) {
		return Intl.NumberFormat("en-IN").format(number)
	}
	const suffixes = [
		{ value: 1e7, symbol: shortPostFix ? "Cr" : "crores" },
		{ value: 1e5, symbol: shortPostFix ? "L" : "lakhs" },
		{ value: 1e3, symbol: "K" },
	]

	const suffix = suffixes.find(items => number >= items.value)
	if (suffix) {
		const baseValue = Math.floor(number / suffix.value)
		const roundedBaseValue = roundToNDigits(baseValue, countDigits(baseValue))

		if (isCurrency) {
			return `${getCommaSeparatedInrString(roundedBaseValue)}+ ${suffix?.symbol}`
		}
		return `${Intl.NumberFormat("en-IN").format(roundedBaseValue)}+ ${suffix.symbol}`
	}

	return Intl.NumberFormat("en-IN").format(number)
}

export const concatStrings = (...args: string[]): string => {
	return args.map(String).join("")
}

export const countDigits = (input: number): number => {
	return input?.toString().replace(/\D/g, "").length
}

export const convertToIndSystemNumberFormatter = (number: number): string => {
	return Intl.NumberFormat("en-IN").format(number)
}

export const truncateWithEllipsis = (str: string, truncLength: number) =>
	str.length > truncLength ? `${str.substring(0, truncLength)}...` : str

export const getInitialsFromName = (name?: string): string => {
	const initials = name
		?.match(/(\b\S)?/g)
		?.join("")
		.toUpperCase()
		.substring(0, 2)

	return initials || name?.at(0)?.toUpperCase() || "--"
}

export const capitalizeFirstLetterInSentence = (str: string) => {
	const splitStr = str.toLowerCase().split(" ")

	splitStr.forEach((item, index) => {
		splitStr[index] =
			splitStr[index].charAt(0).toUpperCase() + splitStr[index].substring(1)
	})

	return splitStr.join(" ")
}
