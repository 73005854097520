import { createContext, useContext, useMemo } from "react";
type LandingContextProviderProps = {
  children: React.ReactNode;
  landingStrapiData: any;
  assetsData: any;
  listingPageData: any;
};
type DefaultContext = {
  landingStrapiData: any;
  assetsData: any;
  listingPageData: any;
};
const defaultContext: DefaultContext = {
  landingStrapiData: {},
  assetsData: {},
  listingPageData: {}
};
const LandingContext = createContext<DefaultContext>(defaultContext);
export const useLandingContext = () => useContext(LandingContext);
export const LandingContextProvider: React.FC<LandingContextProviderProps> = ({
  landingStrapiData,
  assetsData,
  listingPageData,
  children
}) => {
  const value = useMemo(() => ({
    landingStrapiData,
    assetsData,
    listingPageData
  }), [landingStrapiData, assetsData, listingPageData]);
  return <LandingContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="LandingContextProvider" data-sentry-source-file="index.tsx">{children}</LandingContext.Provider>;
};