import styled, { css } from "styled-components";
import Link from "next/link";
import { ArrowRight24StrokedIcon } from "wint-icons";
import { getImagesCdnUrl } from "@common/utils/env/index";
import Image from "next/image";
import { ButtonDefaultText, HeadingSansSerifMediumText, HeadingSansSerifSmallText } from "@common/styles/typographies";
import Button from "@wint_design_system/molecules/Buttons/Button";
import { minDesktopCss } from "@common/styles/media";
import { useAppContext } from "@common/context/AppContext";
import { useEffect, useState } from "react";
import { getIsIOSDeviceByUserAgent } from "@common/utils/browser/index";
import DownloadAppModal from "@common/components/DownloadApp/DownloadAppModal";
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS";
import { DOWNLOAD_APP_CARD_LINKS } from "@common/constants/download_app/index";
import { countDigits, roundToNDigits } from "@common/utils/string";
import { usePlatformDataContext } from "@common/context/PlatformDataContext";
import Counter from "../Counter/index";
import { LinkedinIcon, RegulatedIcon, TwitterIcon, WintDarkLogo, YoutubeIcon } from "../../constants/icons";
const nseLogo = `${getImagesCdnUrl()}/landingPageV3/nse.svg`;
const groupLogo = `${getImagesCdnUrl()}/landingPageV3/group.svg`;
const FooterInfo = () => {
  const [iOS, setIOS] = useState<any>();
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const {
    isDesktop
  } = useAppContext();
  const {
    platformData
  } = usePlatformDataContext();
  useEffect(() => {
    setIOS(getIsIOSDeviceByUserAgent(navigator?.userAgent));
  }, []);
  const handleDownloadCTA = () => {
    if (isDesktop) {
      setShowDownloadModal(true);
    } else if (iOS) {
      window.open(DOWNLOAD_APP_CARD_LINKS?.ios, "_self");
    } else {
      window.open(DOWNLOAD_APP_CARD_LINKS?.android, "_self");
    }
  };
  const handleDownloadModalCloseClick = () => {
    setShowDownloadModal(false);
  };
  return <FooterInfoContainer data-sentry-element="FooterInfoContainer" data-sentry-component="FooterInfo" data-sentry-source-file="FooterInfo.tsx">
			<LogoContainer data-sentry-element="LogoContainer" data-sentry-source-file="FooterInfo.tsx">
				<WintDarkLogo width={isDesktop ? "136" : "96"} height={isDesktop ? "38" : "28"} data-sentry-element="WintDarkLogo" data-sentry-source-file="FooterInfo.tsx" />
				<SocialLogoContainer data-sentry-element="SocialLogoContainer" data-sentry-source-file="FooterInfo.tsx">
					<Link id={LANDING_CLICK_IDS.twitter} href="https://x.com/WintWealth" target="_blank" data-sentry-element="Link" data-sentry-source-file="FooterInfo.tsx">
						<TwitterIcon data-sentry-element="TwitterIcon" data-sentry-source-file="FooterInfo.tsx" />
					</Link>
					<Link id={LANDING_CLICK_IDS.youtube} href="https://www.youtube.com/channel/UCggPd3Vf9ooG2r4I_ZNWBzA" target="_blank" data-sentry-element="Link" data-sentry-source-file="FooterInfo.tsx">
						<YoutubeIcon data-sentry-element="YoutubeIcon" data-sentry-source-file="FooterInfo.tsx" />
					</Link>
					<Link id={LANDING_CLICK_IDS.linkedin} href="https://www.linkedin.com/company/wintwealth/" target="_blank" data-sentry-element="Link" data-sentry-source-file="FooterInfo.tsx">
						<LinkedinIcon data-sentry-element="LinkedinIcon" data-sentry-source-file="FooterInfo.tsx" />
					</Link>
				</SocialLogoContainer>
			</LogoContainer>

			<TrustFlex data-sentry-element="TrustFlex" data-sentry-source-file="FooterInfo.tsx">
				<TrustInfoContainer data-sentry-element="TrustInfoContainer" data-sentry-source-file="FooterInfo.tsx">
					<InvestorInfoBox data-sentry-element="InvestorInfoBox" data-sentry-source-file="FooterInfo.tsx">
						<Image src={groupLogo} width={56} height={56} alt="Group Logo" data-sentry-element="Image" data-sentry-source-file="FooterInfo.tsx" />
						<BoxContent data-sentry-element="BoxContent" data-sentry-source-file="FooterInfo.tsx">
							<HeadingSansSerifMediumText data-sentry-element="HeadingSansSerifMediumText" data-sentry-source-file="FooterInfo.tsx">
								{" "}
								<Counter value={roundToNDigits(platformData?.totalInvestors, countDigits(platformData?.totalInvestors))} data-sentry-element="Counter" data-sentry-source-file="FooterInfo.tsx" />
								+
							</HeadingSansSerifMediumText>
							<BoxText data-sentry-element="BoxText" data-sentry-source-file="FooterInfo.tsx">Investors trust Wint Wealth</BoxText>
						</BoxContent>
					</InvestorInfoBox>
					<Flex data-sentry-element="Flex" data-sentry-source-file="FooterInfo.tsx">
						<SmallInfoBox data-sentry-element="SmallInfoBox" data-sentry-source-file="FooterInfo.tsx">
							<RegulatedIcon data-sentry-element="RegulatedIcon" data-sentry-source-file="FooterInfo.tsx" />
							<HeadingSansSerifSmallText data-sentry-element="HeadingSansSerifSmallText" data-sentry-source-file="FooterInfo.tsx">
								Regulated by SEBI
							</HeadingSansSerifSmallText>
						</SmallInfoBox>
						<SmallInfoBox data-sentry-element="SmallInfoBox" data-sentry-source-file="FooterInfo.tsx">
							<Image src={nseLogo} width={40} height={40} alt="Group Logo" data-sentry-element="Image" data-sentry-source-file="FooterInfo.tsx" />
							<HeadingSansSerifSmallText data-sentry-element="HeadingSansSerifSmallText" data-sentry-source-file="FooterInfo.tsx">
								Partnered with NSE
							</HeadingSansSerifSmallText>
						</SmallInfoBox>
					</Flex>
				</TrustInfoContainer>

				<Divider data-sentry-element="Divider" data-sentry-source-file="FooterInfo.tsx" />

				<PortfolioContainer data-sentry-element="PortfolioContainer" data-sentry-source-file="FooterInfo.tsx">
					<PortfolioTitle data-sentry-element="PortfolioTitle" data-sentry-source-file="FooterInfo.tsx">
						Diversify portfolio with high returns now
					</PortfolioTitle>
					<Button clickId={LANDING_CLICK_IDS.download_footer} type="primary" text={<ButtonDefaultText id={LANDING_CLICK_IDS.download_footer} $mobColor="#ffffff" $webColor="#ffffff">
								Download App
							</ButtonDefaultText>} size="medium" spacing="space-between" desktopStyles={{
          width: "256px"
        }} onClick={handleDownloadCTA} icon={<ArrowRight24StrokedIcon color="#7e7e7e" style={{
          transform: "rotate(-45deg)"
        }} />} data-sentry-element="Button" data-sentry-source-file="FooterInfo.tsx" />
				</PortfolioContainer>
			</TrustFlex>
			{isDesktop && <Divider />}

			<DownloadAppModal show={showDownloadModal} onClose={handleDownloadModalCloseClick} data-sentry-element="DownloadAppModal" data-sentry-source-file="FooterInfo.tsx" />
		</FooterInfoContainer>;
};
export default FooterInfo;
const FooterInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 24px;
`;
const LogoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		cursor: pointer;
	}
`;
const SocialLogoContainer = styled.div`
	display: flex;
	gap: 12px;
`;
const TrustInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px 0;
	gap: 16px;

	${minDesktopCss(css`
		flex-direction: row;
		flex-basis: 50%;
	`)}
`;
const InvestorInfoBox = styled.div`
	display: flex;
	padding: 16px;
	width: 100%;
	border-radius: 4px;
	background: #f2f2f2;
	gap: 12px;

	${minDesktopCss(css`
		flex-basis: 40%;
		flex-direction: column;
		justify-content: space-between;
	`)}
`;
const SmallInfoBox = styled(InvestorInfoBox)`
	flex-direction: column;

	${minDesktopCss(css`
		flex-direction: row;
		flex: 1;
		align-items: center;
		justify-content: flex-start;
	`)}
`;
const BoxContent = styled.div`
	display: flex;
	flex-direction: column;
`;
const BoxText = styled.p`
	color: #7e7e7e;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
const Flex = styled.div`
	display: flex;
	gap: 16px;

	${minDesktopCss(css`
		flex-direction: column;
		flex-basis: 60%;
		justify-content: space-between;
	`)}
`;
const Divider = styled.div`
	height: 1px;
	background: #f2f2f2;
	margin: 24px 0px 0px 0;

	${minDesktopCss(css`
		margin: 24px 0px 56px 0px;
	`)}
`;
const PortfolioContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 48px 0px;

	${minDesktopCss(css`
		flex-basis: 50%;
		padding: 24px 0px;
	`)}
`;
const PortfolioTitle = styled.p`
	color: #000;
	font-family: var(--font-lora);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px; /* 133.333% */

	${minDesktopCss(css`
		font-size: 2.5rem;
		line-height: 56px;
	`)}
`;
const TrustFlex = styled.div`
	display: flex;
	flex-direction: column;

	${minDesktopCss(css`
		flex-direction: row-reverse;
		justify-content: space-between;
		gap: 56px;
	`)}
`;