import { SITE_URLS } from "@common/constants"
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS"

export const FOOTER_MENU = [
	{
		title: "PRODUCTS",
		items: [
			{
				name: "Bonds",
				link: SITE_URLS.BONDS,
				clickId: LANDING_CLICK_IDS.bonds_footer,
				showSignupModal: true,
			},
			// {
			// 	name: "FDs",
			// 	link: SITE_URLS.FD_HOME,
			// 	clickId: LANDING_CLICK_IDS.fd_footer,
			// 	showSignupModal: true,
			// },
			{
				name: "SGBs",
				link: "https://sgb.wintwealth.com/",
				clickId: LANDING_CLICK_IDS.sgb_footer,
				showSignupModal: true,
			},
		],
	},
	{
		title: "ABOUT",
		items: [
			{
				name: "Wint Capital",
				link: "https://www.wint.capital/",
				clickId: LANDING_CLICK_IDS.wint_capital_footer,
				showSignupModal: false,
			},
			{
				name: "Our Story",
				link: "/about-us/",
				clickId: LANDING_CLICK_IDS.our_story_footer,
				showSignupModal: false,
			},
			{
				name: "Contact Us",
				link: "/contact-us/",
				clickId: LANDING_CLICK_IDS.contact_us_footer,
				showSignupModal: false,
			},
			{
				name: "Blogs",
				link: "/blog/",
				clickId: LANDING_CLICK_IDS.blogs_footer,
				showSignupModal: false,
			},
		],
	},
	{
		title: "IMPORTANT LINKS",
		items: [
			{
				name: "Terms and Conditions",
				link: "/terms-and-conditions/",
				clickId: LANDING_CLICK_IDS.tnc_footer,
				showSignupModal: false,
			},
			{
				name: "Privacy Policy",
				link: "/privacy-policy/",
				clickId: LANDING_CLICK_IDS.privacy_footer,
				showSignupModal: false,
			},
			{
				name: "Investor Awarenesss",
				link: "/investor-awareness/",
				clickId: LANDING_CLICK_IDS.investor_awareness_footer,
				showSignupModal: false,
			},
			{
				name: "Risk Disclosures & Disclaimers",
				link: "/risk-disclosures-disclaimers/",
				clickId: LANDING_CLICK_IDS.risk_disclosure_footer,
				showSignupModal: false,
			},
		],
	},
]
