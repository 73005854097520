"use client";

import styled, { css } from "styled-components";
import { useInView } from "framer-motion";
import { Fit, Layout, useRive } from "@rive-app/react-canvas";
import { minDesktopCss } from "@common/styles/media";
import { useEffect, useRef } from "react";
import { useAppContext } from "@common/context/AppContext";
import { useLandingContext } from "@features/Landing/contexts/index";
import AnimatedTitle from "../AnimatedTitle/index";
const Repayment = () => {
  const ref = useRef(null);
  const {
    isMobile,
    isTablet,
    isDesktop
  } = useAppContext();
  const isInView = useInView(ref, {
    once: true,
    amount: 0.3
  });
  const isSecitonInView = useInView(ref, {
    once: true,
    amount: 0.1
  });
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    repayment
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  const {
    rive,
    RiveComponent
  } = useRive({
    src: repayment?.assetLink,
    stateMachines: "Polaroid actions2",
    artboard: "Artboard 2",
    isTouchScrollEnabled: true,
    autoplay: true,
    animations: "idle",
    layout: new Layout({
      fit: isMobile || isTablet ? Fit.Cover : Fit.FitHeight
    })
  });
  useEffect(() => {
    if (rive) {
      if (isInView) {
        rive.play();
      } else {
        rive.pause();
      }
    }
  }, [rive, isInView]);
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="Repayment" data-sentry-source-file="index.tsx">
			{isSecitonInView && <TitleContainer>
					{repayment?.sectionTitle?.map((data: any, index: number) => <AnimatedTitle key={index} textColor={data?.textColor} fontSize={isDesktop ? "72px" : "28px"} text={data?.title} fontWeight={600} />)}
				</TitleContainer>}

			<RiveContainer ref={ref} data-sentry-element="RiveContainer" data-sentry-source-file="index.tsx">{isInView && <RiveComponent />}</RiveContainer>
		</Wrapper>;
};
export default Repayment;
const Wrapper = styled.div`
	width: 100%;
	padding: 120px 0px 80px 0px;

	${minDesktopCss(css`
		padding: 10% 200px 80px 200px;
	`)}
`;
const RiveContainer = styled.div`
	display: flex;
	height: 70vh;
	width: 100%;
	z-index: -99;

	${minDesktopCss(css`
		height: 80vh;
	`)}
`;
const TitleContainer = styled.h2`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;