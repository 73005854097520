import { useAppContext } from "@common/context/AppContext";
import { minDesktopCss } from "@common/styles/media";
import Image from "next/image";
import { BodyMediumText, ButtonDefaultText, HeadingSerifMediumText } from "@common/styles/typographies";
import Button from "@wint_design_system/molecules/Buttons/Button";
import styled, { css, useTheme } from "styled-components";
import { GmailIcon, WhatsappIcon } from "wint-icons";
import { handleEmailUsClick, handleWhatsAppUsClick } from "@common/utils/contact";
import { getImagesCdnUrl } from "@common/utils/env";
type ContactProps = {
  whatsAppId?: any;
  emailId?: any;
};
export const ASSETS_URL = `${getImagesCdnUrl()}/homePage`;
const contactUsImage = `${getImagesCdnUrl()}/landingPageV3/contact.svg`;
const LandingContactBanner: React.FC<ContactProps> = ({
  whatsAppId = null,
  emailId = null
}) => {
  const {
    isDesktop,
    isMobile,
    isTablet
  } = useAppContext();
  const theme = useTheme();
  return <ContactUsWrapper data-sentry-element="ContactUsWrapper" data-sentry-component="LandingContactBanner" data-sentry-source-file="contactUsBanner.tsx">
			<LeftSection data-sentry-element="LeftSection" data-sentry-source-file="contactUsBanner.tsx">
				{isDesktop && <Image src={contactUsImage} width={216} height={158} alt="contact banner" />}

				<TitleWrapper data-sentry-element="TitleWrapper" data-sentry-source-file="contactUsBanner.tsx">
					<HeadingSerifMediumText data-sentry-element="HeadingSerifMediumText" data-sentry-source-file="contactUsBanner.tsx">
						Want to learn more? <br /> Connect with us now!
					</HeadingSerifMediumText>
					<BodyMediumText $webColor={theme?.colors.text.tertiary} $mobColor={theme?.colors.text.tertiary} data-sentry-element="BodyMediumText" data-sentry-source-file="contactUsBanner.tsx">
						Our support team will help you within 2 hours.
					</BodyMediumText>
				</TitleWrapper>
				<ButtonWrapper data-sentry-element="ButtonWrapper" data-sentry-source-file="contactUsBanner.tsx">
					<Button clickId={whatsAppId} themeInverse text={<ButtonDefaultText id={whatsAppId}>WhatsApp Us</ButtonDefaultText>} size="medium" spacing="space-between" type="secondary" icon={<WhatsappIcon />} onClick={handleWhatsAppUsClick} data-sentry-element="Button" data-sentry-source-file="contactUsBanner.tsx" />
					{isDesktop && <Button clickId={emailId} themeInverse text={<ButtonDefaultText id={emailId}>Email us</ButtonDefaultText>} size="medium" spacing="space-between" type="secondary" icon={<GmailIcon />} onClick={handleEmailUsClick} />}
				</ButtonWrapper>
			</LeftSection>

			{isMobile || isTablet ? <RightSection src={!isDesktop ? `${ASSETS_URL}/contact_banner_mob.svg` : `${ASSETS_URL}/contact_banner_desk.svg`} alt="cover" /> : null}
		</ContactUsWrapper>;
};
export default LandingContactBanner;
const ContactUsWrapper = styled.div`
	width: 100%;
	display: flex;
	gap: 1rem;
	justify-content: center;

	${minDesktopCss(css`
		height: 164px;
		padding-left: 0;
		justify-content: flex-start;
	`)}
`;
const LeftSection = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	justify-content: center;

	${minDesktopCss(css`
		justify-content: flex-start;
	`)}
`;
const RightSection = styled.img`
	width: 45%;
	height: 100%;
	${minDesktopCss(css`
		width: 41%;
	`)}
`;
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;
const ButtonWrapper = styled.div`
	display: flex;
	height: 48px;
	width: 100%;
	gap: 16px;
`;