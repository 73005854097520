import { useState } from "react";
import styled, { css, useTheme } from "styled-components";
import AccordionItem, { Accordion } from "@wint_design_system/molecules/Accordion/AccordionItem";
import { useLandingContext } from "@features/Landing/contexts/index";
import { minDesktopCss } from "@common/styles/media";
import { HeadingSerifMediumText } from "@common/styles/typographies";
import LandingContactBanner from "./contactUsBanner";
const HelpUs = () => {
  const DEFAULT_FAQ_COUNT = 6;
  const theme = useTheme();
  const [faqCount, setFaqCount] = useState(DEFAULT_FAQ_COUNT);
  const [faqToggle, setFaqToggle] = useState(false);
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    faqs
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  const handleFaqCount = () => {
    if (faqToggle) {
      setFaqCount(DEFAULT_FAQ_COUNT);
    } else {
      setFaqCount(faqs?.length);
    }
    setFaqToggle(!faqToggle);
  };
  return <SectionWrapper data-sentry-element="SectionWrapper" data-sentry-component="HelpUs" data-sentry-source-file="index.tsx">
			<Wrapper data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
				<Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
					<FAQSection id="#FAQPage" data-sentry-element="FAQSection" data-sentry-source-file="index.tsx">
						<HeadingSerifMediumText $mobColor={theme.colors.text.tertiary} $webColor={theme.colors.text.tertiary} data-sentry-element="HeadingSerifMediumText" data-sentry-source-file="index.tsx">
							FAQs
						</HeadingSerifMediumText>
						<div>
							<Accordion data-sentry-element="Accordion" data-sentry-source-file="index.tsx">
								{faqs?.slice(0, faqCount)?.map((guideline: any, index: number) => <AccordionItemWrapper key={guideline.question + index.toString()} $isFirstItem={index === 0}>
											<AccordionItem title={guideline.question} content={guideline.answer} parseHTML />
										</AccordionItemWrapper>)}
							</Accordion>
							<ViewMoreContainer onClick={handleFaqCount} data-sentry-element="ViewMoreContainer" data-sentry-source-file="index.tsx">
								{faqs?.length <= 5 ? null : <ViewMore> {faqToggle ? "View less" : "View more"} </ViewMore>}
							</ViewMoreContainer>
						</div>
					</FAQSection>

					<ContactUsSection data-sentry-element="ContactUsSection" data-sentry-source-file="index.tsx">
						<LandingContactBanner data-sentry-element="LandingContactBanner" data-sentry-source-file="index.tsx" />
					</ContactUsSection>
				</Container>
			</Wrapper>
		</SectionWrapper>;
};
export default HelpUs;
const SectionWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Wrapper = styled.div`
	width: 100%;
	padding: 48px 24px;

	${minDesktopCss(css`
		padding: 100px 200px;
		max-width: 1500px;
	`)}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 56px;

	${minDesktopCss(css`
		flex-direction: row;
	`)}
`;
const FAQSection = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 60%;
	gap: 24px;
`;
const ContactUsSection = styled.div`
	display: flex;
	flex-basis: 40%;
`;
const AccordionItemWrapper = styled.div<{
  $isFirstItem: boolean;
}>`
	margin-top: ${({
  $isFirstItem
}) => $isFirstItem ? "0px" : "24px"};
`;
const ViewMoreContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 16px 0;
`;
const ViewMore = styled.p`
	color: #7e7e7e;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
`;