import { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "next/image";
import { useAppContext } from "@common/context/AppContext";
import { getIsIOSDeviceByUserAgent } from "@common/utils/browser/index";
import { useLandingContext } from "@features/Landing/contexts/index";
import Button from "@wint_design_system/molecules/Buttons/Button";
import { ButtonDefaultText } from "@common/styles/typographies";
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS";
type SmartBannerTypes = {
  downloadAppData?: any;
};
const SmartBanner: React.FC<SmartBannerTypes> = ({
  downloadAppData
}) => {
  const {
    isDesktop
  } = useAppContext();
  const {
    landingStrapiData
  } = useLandingContext();
  const [iOS, setIOS] = useState<any>();
  const {
    smartBanner
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  useEffect(() => {
    setIOS(getIsIOSDeviceByUserAgent(navigator?.userAgent));
  }, []);
  function openUniversalLink() {
    window.open("https://www.wintwealth.com/redirect-to-app");
  }
  const handleAppRating = () => {
    const downloadAppDataTemp = downloadAppData?.data?.attributes?.downloadAppBanner;
    if (iOS) {
      const iosData = downloadAppDataTemp.find((item: any) => item.deviceType?.toLowerCase() === "ios");
      return iosData?.rating;
    }
    const androidData = downloadAppDataTemp.find((item: any) => item.deviceType?.toLowerCase() === "android");
    return androidData?.rating;
  };
  return <div data-sentry-component="SmartBanner" data-sentry-source-file="smartBanner.tsx">
			{smartBanner?.filter((item: any) => {
      if (!isDesktop) {
        if (iOS) {
          return item.deviceType?.toLowerCase() !== "android";
        }
        return item.deviceType?.toLowerCase() !== "ios";
      }
      return true;
    })?.map((data: any, index: number) => <Container key={index}>
						<LeftSection>
							<Image src={data?.storeIcon?.data?.attributes?.url} height={24} width={24} alt="appstore icon" />
							<TextContainer>
								<Title> {data?.title} </Title>
								<Rating>
									{handleAppRating()}/5 on {data?.storeName}
								</Rating>
							</TextContainer>
						</LeftSection>

						<RightSection>
							<Button clickId={LANDING_CLICK_IDS.smart_banner_id} text={<ButtonDefaultText id={LANDING_CLICK_IDS.smart_banner_id} $mobColor="#34a853">
										{data?.ctaText}
									</ButtonDefaultText>} type="tertiary" onClick={() => openUniversalLink()} />
						</RightSection>
					</Container>)}
		</div>;
};
export default SmartBanner;
const Container = styled.div`
	width: 100%;
	background: ${props => props.theme.colors.bg.section.default};
	padding: 12px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;
const LeftSection = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;
const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;
const Title = styled.h2`
	font-size: 14px;
	font-weight: 500;
	color: ${props => props.theme.colors.text.primary};
	margin: 0;
`;
const Rating = styled.p`
	color: ${props => props.theme.colors.text.tertiary};
	margin: 0;
	font-size: 12px;
`;
const RightSection = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;