"use client";

import { LandingContextProvider } from "@features/Landing/contexts/index";
type LandingPageProviderTypes = {
  children: React.ReactNode;
  landingStrapiData: any;
  assetsData: any;
  listingPageData: any;
};
const LandingPageProvider: React.FC<LandingPageProviderTypes> = ({
  landingStrapiData,
  assetsData,
  listingPageData,
  children
}) => {
  return <LandingContextProvider landingStrapiData={landingStrapiData} assetsData={assetsData} listingPageData={listingPageData} data-sentry-element="LandingContextProvider" data-sentry-component="LandingPageProvider" data-sentry-source-file="index.tsx">
			{children}
		</LandingContextProvider>;
};
export default LandingPageProvider;