"use client";

import DownloadAppCard from "@common/components/DownloadApp/DownloadAppCard";
import { DOWNLOAD_APP_CARD_LINKS } from "@common/constants/download_app/index";
import { useAppContext } from "@common/context/AppContext";
import { minDesktopCss } from "@common/styles/media";
import { BodySmallText } from "@common/styles/typographies";
import { getIsIOSDeviceByUserAgent } from "@common/utils/browser/index";
import { useLandingContext } from "@features/Landing/contexts/index";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import StarRating from "./starRating";
type AppInfoProps = {
  strapiResponseData?: any;
};
const AppInfo: React.FC<AppInfoProps> = ({
  strapiResponseData
}) => {
  const {
    isDesktop
  } = useAppContext();
  const [iOS, setIOS] = useState<any>();
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    appInfo
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  useEffect(() => {
    setIOS(getIsIOSDeviceByUserAgent(navigator?.userAgent));
  }, []);
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="AppInfo" data-sentry-source-file="index.tsx">
			<Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
				<RatingSection data-sentry-element="RatingSection" data-sentry-source-file="index.tsx">
					<Title data-sentry-element="Title" data-sentry-source-file="index.tsx">{appInfo?.sectionTitle}</Title>
					{!isDesktop && <StyledBodySmallText>
							{" "}
							{iOS ? "on appstore" : "on playstore"}{" "}
						</StyledBodySmallText>}
					<RatingFigureSection data-sentry-element="RatingFigureSection" data-sentry-source-file="index.tsx">
						{strapiResponseData?.data?.attributes?.downloadAppBanner?.filter((item: any) => {
            if (!isDesktop) {
              if (iOS) {
                return item.deviceType?.toLowerCase() !== "android";
              }
              return item.deviceType?.toLowerCase() !== "ios";
            }
            return true;
          })?.map((data: any, index: number) => <RatingBox key={index}>
									{isDesktop && <BodySmallText> {data?.storeName} </BodySmallText>}
									<RatingText> {data?.rating} </RatingText>
									<StarRating rating={data?.rating} />
								</RatingBox>)}
					</RatingFigureSection>
				</RatingSection>

				<AppSection data-sentry-element="AppSection" data-sentry-source-file="index.tsx">
					{strapiResponseData?.data?.attributes?.downloadAppBanner?.filter((item: any) => {
          if (!isDesktop) {
            if (iOS) {
              return item.deviceType?.toLowerCase() !== "android";
            }
            return item.deviceType?.toLowerCase() !== "ios";
          }
          return true;
        })?.map((data: any, index: number) => <DownloadAppCard key={index} cardData={data} link={DOWNLOAD_APP_CARD_LINKS?.[data?.deviceType?.toLowerCase()]} />)}
				</AppSection>
			</Container>
		</Wrapper>;
};
export default AppInfo;
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 80px 1.5rem;
	background: #fafafa;

	${minDesktopCss(css`
		padding: 80px 200px;
		flex-direction: row;
		background: #fff;
		max-width: 1500px;
	`)}
`;
const RatingSection = styled.div`
	display: flex;
	flex-basis: 50%;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${minDesktopCss(css`
		align-items: flex-start;
	`)}
`;
const RatingFigureSection = styled.div`
	display: flex;
	gap: 56px;
`;
const RatingBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 32px 0px 48px 0px;

	${minDesktopCss(css`
		flex-basis: 50%;
		align-items: flex-start;
		padding: 40px 0px 0px 0px;
	`)}
`;
const RatingText = styled.p`
	color: #012e01;
	font-family: var(--font-space-grotesk);
	font-size: 96px;
	font-style: normal;
	font-weight: 500;
	line-height: 96px; /* 100% */

	${minDesktopCss(css`
		font-size: 80px;
		line-height: 80px;
	`)}
`;
const AppSection = styled.div`
	display: flex;
	flex-basis: 50%;
	gap: 1rem;
`;
export const Title = styled.div`
	color: #000;
	font-family: var(--font-bricolage-grotesque);
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 40px;
	text-align: center;

	${minDesktopCss(css`
		text-align: left;
		font-size: 40px;
	`)}
`;
const StyledBodySmallText = styled(BodySmallText)`
	font-family: var(--font-bricolage-grotesque);
	color: #7e7e7e;
`;