import { useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";
import { indianNumberWithNotationPostFix } from "@common/utils/string/index";
type CounterTypes = {
  value: number;
  direction?: string;
  isCurrency?: boolean;
};
const Counter: React.FC<CounterTypes> = ({
  value,
  direction,
  isCurrency = false
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(direction === "down" ? value : 0);
  const springValue = useSpring(motionValue, {
    damping: 30,
    stiffness: 100
  });
  const isInView = useInView(ref);
  useEffect(() => {
    if (isInView) {
      motionValue.set(direction === "down" ? 0 : value);
    }
  }, [motionValue, isInView]);
  useEffect(() => springValue.on("change", (latest: any) => {
    if (ref.current) {
      ref.current.textContent = isCurrency ? indianNumberWithNotationPostFix({
        number: latest
      }) : Intl.NumberFormat("en-US").format(latest.toFixed(0));
    }
  }), [springValue]);
  return <span ref={ref} data-sentry-component="Counter" data-sentry-source-file="index.tsx" />;
};
export default Counter;