export const wordAnimation = {
  hidden: {},
  visible: {}
};
export const characterAnimation = (duration?: number) => {
  return {
    hidden: {
      y: "2em",
      skew: 10
    },
    visible: {
      y: "0em",
      skew: 0,
      transition: {
        duration: duration || 1,
        ease: [0.45, 0, 0.55, 1]
      }
    }
  };
};