"use client";

import { useRef } from "react";
import { useInView } from "framer-motion";
import styled, { css } from "styled-components";
import { minDesktopCss } from "@common/styles/media";
import { useAppContext } from "@common/context/AppContext";
import { useLandingContext } from "@features/Landing/contexts/index";
import AnimatedTitle from "../AnimatedTitle/index";
type CompareBondTypes = {
  isTransitionedLayout: boolean;
};
const CompareBond: React.FC<CompareBondTypes> = ({
  isTransitionedLayout
}) => {
  const ref = useRef(null);
  const textRef = useRef(null);
  const {
    isDesktop
  } = useAppContext();
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    compareBond
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  const isInView = useInView(ref, {
    once: true,
    amount: 0.1
  });
  return <PageWrapper data-sentry-element="PageWrapper" data-sentry-component="CompareBond" data-sentry-source-file="index.tsx">
			<Wrapper ref={ref} $isTransitionedLayout={isTransitionedLayout} data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
				<Container $isTransitionedLayout={isTransitionedLayout} ref={textRef} data-sentry-element="Container" data-sentry-source-file="index.tsx">
					<Content data-sentry-element="Content" data-sentry-source-file="index.tsx">
						{isInView && <TitleContainer>
								{compareBond?.sectionTitle?.map((data: any, index: number) => <AnimatedTitle key={index} textColor={data?.textColor} text={data?.title} activeAfterIndex={data?.activeAfterIndex} fontWeight={600} fontSize={isDesktop ? "72px" : "28px"} />)}
							</TitleContainer>}

						<iframe title="comparebond" style={{
            willChange: "transform"
          }} src={isDesktop ? compareBond?.files?.deskLink : compareBond?.files?.mobLink} width="100%" height="100%" frameBorder="0" />
					</Content>
				</Container>
			</Wrapper>
		</PageWrapper>;
};
export default CompareBond;
const PageWrapper = styled.div`
	padding: 80px 0px;

	${minDesktopCss(css`
		padding: 80px 0px;
	`)}
`;
const Wrapper = styled.div<{
  $isTransitionedLayout?: boolean;
}>`
	display: flex;
	width: 100%;
	height: auto;
	padding: 0px 8px;
	justify-content: center;
	align-items: center;
`;
const Container = styled.div<{
  $isTransitionedLayout?: boolean;
}>`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: transparent;
	border-radius: 8px;
	transform: ${({
  $isTransitionedLayout
}) => $isTransitionedLayout ? "scale(0.94)" : "scale(1)"};
	transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

	${minDesktopCss(css`
		border-radius: 16px;
		padding: 45px 200px;
		max-width: 1500px;
		transform: unset;
	`)}
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	background: ${props => props.theme.colors.bg.section.default};
	border-radius: 8px;
	padding: 16px 0;
	justify-content: center;
	align-items: center;
	height: 65vh;

	iframe {
		margin-top: -20%;
	}

	${minDesktopCss(css`
		border-radius: 16px;
		height: 100vh;
		max-height: 800px;

		iframe {
			margin-top: -15%;
		}
	`)}
`;
const TitleContainer = styled.h2`
	display: flex;
	flex-direction: column;
	z-index: 99;
`;