import { useLayoutEffect } from "react";
type UseLockBodyScrollParams = {
  isLocked?: boolean;
} | undefined;
export default function useLockBodyScroll(params: UseLockBodyScrollParams = {}) {
  const {
    isLocked
  } = params;
  useLayoutEffect(() => {
    const defaultOverflow = window.getComputedStyle(document.body).overflow;
    if (!isLocked) return;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = defaultOverflow;
    };
  }, [isLocked]);
}