import { WINT_CONTACT_EMAIL } from "@common/constants/wint"
import { USER_LOCAL_STORAGE_KEYS } from "@common/constants/storage_keys"
import { getFromLocalStorage, isIOSWebview, isWebview } from "../browser"

export const handleEmailUsClick = (): void => {
	const emailUsLink = getFromLocalStorage<string | undefined>(
		USER_LOCAL_STORAGE_KEYS.USER_EMAIL,
	)?.includes("@gmail")
		? `https://mail.google.com/mail/u/0/?fs=1&to=${WINT_CONTACT_EMAIL}&tf=cm`
		: `mailto:${WINT_CONTACT_EMAIL}?subject=Hello%20Wint%20Wealth&body=Hi%20Wint%20Wealth%20Team%2C%20I%20have%20a%20question.`

	if (isWebview()) {
		window.Android?.openHelpNative()
	} else if (isIOSWebview()) {
		window.webkit?.messageHandlers?.helpIconClickedIOS?.postMessage(null)
	} else {
		window.open(emailUsLink)
	}
}

export const handleWhatsAppUsClick = (): void => {
	const whatsAppUsLink =
		"https://api.whatsapp.com/send?phone=917904710557&text=Hi%20Wint%20Wealth%20Team,%20I%20have%20a%20question%20and%20would%20like%20to%20get%20in%20touch%20with%20you.&source=&data="

	if (isWebview()) {
		window.Android?.openSocialNative(whatsAppUsLink)
	} else if (isIOSWebview()) {
		window.webkit?.messageHandlers?.openIOSSocialNative?.postMessage({
			link: whatsAppUsLink,
		})
	} else {
		window.open(whatsAppUsLink)
	}
}
