import { useRef } from "react";
import Image from "next/image";
import { minDesktopCss } from "@common/styles/media";
import { useLandingContext } from "@features/Landing/contexts/index";
import { usePlatformDataContext } from "@common/context/PlatformDataContext";
import styled, { css } from "styled-components";
import Counter from "../Counter/index";
type ObjTypes = {
  value: number;
};
type MetricType = {
  title: string;
  description: string;
};
const Default = () => {
  const ref = useRef(null);
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    zeroDefault
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  const {
    platformData
  } = usePlatformDataContext();
  const platformDataObj: Record<string, ObjTypes> = {
    totalAmountOfBondsSold: {
      value: platformData?.totalAmountOfBondsSold
    },
    totalAmountOfInterestRepaidBonds: {
      value: platformData?.totalAmountOfInterestRepaidBonds
    }
  };
  return <SectionWrapper data-sentry-element="SectionWrapper" data-sentry-component="Default" data-sentry-source-file="index.tsx">
			<Wrapper data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
				<Container ref={ref} data-sentry-element="Container" data-sentry-source-file="index.tsx">
					<ContentWrapper data-sentry-element="ContentWrapper" data-sentry-source-file="index.tsx">
						<Content data-sentry-element="Content" data-sentry-source-file="index.tsx">
							<DefaultTitle data-sentry-element="DefaultTitle" data-sentry-source-file="index.tsx">{zeroDefault?.sectionTitle}</DefaultTitle>
							<SubText data-sentry-element="SubText" data-sentry-source-file="index.tsx">{zeroDefault?.subTitle}</SubText>

							<RiveContainer data-sentry-element="RiveContainer" data-sentry-source-file="index.tsx">
								<Image src={zeroDefault?.assetLink} width={220} height={310} alt="Illustration of a zero indication zero defaults in bond repayments" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
							</RiveContainer>
						</Content>
					</ContentWrapper>
				</Container>
				<StatContainer $background="transparent" data-sentry-element="StatContainer" data-sentry-source-file="index.tsx">
					<StatWrapper $background="transparent" data-sentry-element="StatWrapper" data-sentry-source-file="index.tsx">
						{zeroDefault?.metrices?.map((metric: MetricType, index: number) => <DataBox key={index}>
								<Figure>
									<Counter isCurrency value={platformDataObj[metric?.title]?.value} />
								</Figure>
								<Text>{metric?.description}</Text>
							</DataBox>)}
					</StatWrapper>
				</StatContainer>
			</Wrapper>
		</SectionWrapper>;
};
export default Default;
type ContainerProps = {
  $background?: string;
};
const SectionWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px 16px 72px 16px;
	justify-content: center;
	align-items: center;
	gap: 16px;
	transition: background-color 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	max-width: 1500px;

	/* ${minDesktopCss(css`
		margin: 70px 200px 120px 200px;
	`)} */
`;
const Container = styled.div<ContainerProps>`
	display: flex;
	width: 100%;
	background: transparent;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	gap: 1rem;
	padding: 48px 0 0 0;
	max-width: 1500px;

	${minDesktopCss(css`
		padding: 70px 200px 0px 200px;
	`)}
`;
const ContentWrapper = styled.div<ContainerProps>`
	display: flex;
	width: 100%;
	background: ${({
  $background
}) => $background || "#000"};
	padding: 40px;
	border-radius: 8px;

	${minDesktopCss(css`
		padding: 80px 0px;
	`)}
`;
const StatWrapper = styled(ContentWrapper)<ContainerProps>`
	gap: 16px;
	padding: 0px;
	width: 100%;
`;
const StatContainer = styled(Container)<ContainerProps>`
	width: 100%;
	padding: 0;

	${minDesktopCss(css`
		padding: 0px 200px 0px 200px;
		width: 80%;
	`)}
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const DefaultTitle = styled.h2`
	color: ${props => props.theme.colors.text.textInverse.primary};
	text-align: center;
	font-family: var(--font-bricolage-grotesque);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;

	${minDesktopCss(css`
		font-size: 80px;
	`)}
`;
const SubText = styled.p`
	color: ${props => props.theme.colors.text.tertiary};
	text-align: center;
	font-family: var(--font-bricolage-grotesque);
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;

	${minDesktopCss(css`
		font-size: 2rem;
		font-weight: 600;
	`)}
`;
const DataBox = styled.div`
	border-radius: 8px;
	border: 1px solid #000;
	background: ${props => props.theme.colors.bg.sectionInverse.subtleInverse};
	height: 92px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${minDesktopCss(css`
		height: 116px;
	`)}
`;
const Figure = styled.p`
	color: ${props => props.theme.colors.text.textInverse.primary};
	text-align: center;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;

	${minDesktopCss(css`
		font-size: 24px;
	`)}
`;
const Text = styled.p`
	color: ${props => props.theme.colors.text.tertiary};
	text-align: center;
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 125% */

	${minDesktopCss(css`
		font-size: 16px;
		font-weight: 500;
	`)}
`;
const RiveContainer = styled.div`
	width: 100%;
	margin-top: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;

	${minDesktopCss(css`
		width: 500px;
	`)}
`;