import styled, { css } from "styled-components";
import Image from "next/image";
import { largeDesktopCss, minDesktopCss } from "@common/styles/media";
import { SectionTitle } from "@features/Landing/styles/index";
import { useLandingContext } from "@features/Landing/contexts/index";
import { useAppContext } from "@common/context/AppContext";
const BackedBy = () => {
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    isDesktop
  } = useAppContext();
  const {
    backedBy
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  return <SectionWrapper data-sentry-element="SectionWrapper" data-sentry-component="BackedBy" data-sentry-source-file="index.tsx">
			<Wrapper data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
				<TitleWrapper data-sentry-element="TitleWrapper" data-sentry-source-file="index.tsx">
					<StyledSectionTitle data-sentry-element="StyledSectionTitle" data-sentry-source-file="index.tsx">{backedBy?.sectionTitle}</StyledSectionTitle>
				</TitleWrapper>
				<InfoCardContainer data-sentry-element="InfoCardContainer" data-sentry-source-file="index.tsx">
					{backedBy?.backedByCard?.map((card: any, index: number) => <InfoCard key={index}>
							<InfoContent>
								<Label>{card?.name}</Label>
								<InfoImageContainer>
									<img src={card?.companyLogo?.data?.attributes?.url} alt="logo" loading="lazy" draggable={false} />
								</InfoImageContainer>
							</InfoContent>
							<ProfilePicContainer>
								<Image src={isDesktop ? card?.profilePic?.data?.attributes?.url : card?.profilePicMob?.data[0]?.attributes?.url} height={isDesktop ? 245 : 152} width={isDesktop ? 246 : 183} draggable={false} alt="Portrait of a prominent person in the Indian finance sector" />
							</ProfilePicContainer>
						</InfoCard>)}
				</InfoCardContainer>
			</Wrapper>
		</SectionWrapper>;
};
export default BackedBy;
const SectionWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 0px 80px 0px;
	gap: 1.5rem;

	${minDesktopCss(css`
		padding: 81px 200px;
		max-width: 1500px;
	`)}

	${largeDesktopCss(css`
		align-items: center;
	`)}
`;
const TitleWrapper = styled.div`
	justify-content: center;
	align-items: center;
	padding: 0px 0px 0px 24px;
	${minDesktopCss(css`
		display: flex;
		width: 100%;
		justify-content: start;
		align-items: center;
		padding: 0px;
	`)}
`;
const InfoCardContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	overflow-x: auto;
	gap: 16px;
	padding: 0px 24px 0px 24px;
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		display: none;
	}

	${minDesktopCss(css`
		justify-content: space-between;
		padding: 0;
	`)}
`;
const InfoCard = styled.div`
	display: flex;
	width: 183px;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	border-radius: 4px;
	border: 0.5px solid var(--color-STROKE-Feedback-Default-Subtle, #e5e5e5);
	flex: 0 0 auto;

	${minDesktopCss(css`
		width: 246px;
		height: 336px;
	`)}
`;
const InfoImageContainer = styled.div`
	display: flex;
	position: relative;

	img {
		height: 100%;
	}
`;
const ProfilePicContainer = styled.div`
	display: flex;
	width: 100%;
	position: relative;
`;
const InfoContent = styled.div`
	display: flex;
	padding: 1rem 1rem;
	flex-direction: column;
	gap: 0.5rem;
`;
const Label = styled.p`
	color: var(--color-TEXT-tertiary, #7e7e7e);
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
const StyledSectionTitle = styled(SectionTitle)`
	font-family: var(--font-bricolage-grotesque);
	font-size: 28px;
	${minDesktopCss(css`
		color: #000;
		font-size: 40px;
		font-style: normal;
		font-weight: 600;
		line-height: 40px; /* 125% */
		text-align: left;
		padding: 0;
	`)}
`;