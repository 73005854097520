"use client";

import { useEffect, useRef, useState } from "react";
import { useInView, motion, useAnimation } from "framer-motion";
import styled from "styled-components";
import { useRouter, useSearchParams } from "next/navigation";
import { useAppContext } from "@common/context/AppContext";
import { SITE_URLS } from "@common/constants/index";
import { isBrowser } from "@common/utils/browser/index";
import { AUTH_TRIGGER_PARAMS } from "@features/Authentication/constants";
import dynamic from "next/dynamic";
import AppInfo from "./components/AppInfo/index";
import BackedBy from "./components/BackedBy/index";
import StabilityBanner from "./components/Banner/stabilityBanner";
import Default from "./components/Default/index";
import Hero from "./components/Hero/index";
import Repayment from "./components/Repayment/index";
import Footer from "./components/Footer/index";
import LandingNav from "./components/LandingNav/index";
import HelpUs from "./components/HelpUs/index";
import CompareBond from "./components/CompareBond/index";
import BottomStatCTA from "./components/BottomStatCTA/index";
import SmartBanner from "./components/Banner/smartBanner";
const DiversifySection = dynamic(() => import("./components/Diversify/index"), {
  ssr: false
});
type LandingPageTypes = {
  downloadAppData?: any;
};
const LandingPage: React.FC<LandingPageTypes> = ({
  downloadAppData
}) => {
  const [navbarTheme, setNavbarTheme] = useState<string>("transparent");
  const [isFixed, setIsFixed] = useState<boolean>(true);
  const [topMargin, setTopMargin] = useState<any>(null);
  const {
    setShowLoginModal,
    isDesktop,
    userState,
    isMobile
  } = useAppContext();
  const ref = useRef<any>(null);
  const heroRef = useRef<any>(null);
  const compareBondRef = useRef<any>(null);
  const defaultSectionRef = useRef<any>(null);
  const repaymentSectionRef = useRef<any>(null);
  const footerRef = useRef<any>(null);
  const diversifyRef = useRef<any>(null);
  const isFooterInView = useInView(footerRef, {
    amount: 0.8
  });
  const isDefaultSectionInView = useInView(defaultSectionRef, {
    amount: 0.7
  });
  const [backgroundColor, setBackgroundColor] = useState<string>("#fff");
  const [isTransitionedLayout, setIsTransitionedLayout] = useState<boolean>(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const affId = searchParams.get("affId");
  const initiateLogin = searchParams.get(AUTH_TRIGGER_PARAMS.initiateLogin) || searchParams.get(AUTH_TRIGGER_PARAMS.initiateFdLogin);
  useEffect(() => {
    if (isBrowser() && affId) {
      localStorage.setItem("AFFILIATE_CODE", affId);
    }
  }, [affId]);
  useEffect(() => {
    if (initiateLogin) {
      setShowLoginModal(true);
    }
  }, [initiateLogin, setShowLoginModal]);
  useEffect(() => {
    if (userState?.isLoggedIn) {
      router.replace(SITE_URLS.BONDS_HOME);
    }
  }, [userState?.isLoggedIn]);
  useEffect(() => {
    const topmargin = Math.floor(350 / window.innerHeight * 100);
    setTopMargin(topmargin);
    const handleScroll = () => {
      const heroRect = heroRef.current.getBoundingClientRect();
      const compareBondRect = compareBondRef.current.getBoundingClientRect();
      const defaultSectionRect = defaultSectionRef.current.getBoundingClientRect();
      const repaymentSectionRect = repaymentSectionRef.current.getBoundingClientRect();
      const stabilitySectionRect = ref.current.getBoundingClientRect();
      const footerRect = footerRef.current.getBoundingClientRect();
      const diversifyRect = diversifyRef.current.getBoundingClientRect();
      if (heroRect.top > -100 && heroRect.top < 0) {
        setNavbarTheme("transparent");
      } else if (heroRect.top < -100 && heroRect.bottom >= 0) {
        setNavbarTheme("blue");
      } else if (diversifyRect.top <= 0 && diversifyRect.bottom >= 0) {
        setNavbarTheme("light");
      } else if (compareBondRect.top <= 0 && compareBondRect.bottom >= 0 && !isTransitionedLayout) {
        setNavbarTheme("light");
      } else if (compareBondRect.top <= 0 && compareBondRect.bottom >= 0 && isTransitionedLayout) {
        setNavbarTheme("dark");
      } else if (defaultSectionRect.top <= 0 && defaultSectionRect.bottom >= 0) {
        setNavbarTheme("dark");
      } else if (repaymentSectionRect.top <= 0 && repaymentSectionRect.bottom >= 0) {
        setNavbarTheme("light");
      } else if (stabilitySectionRect.top <= 0 && stabilitySectionRect.bottom >= 0) {
        setNavbarTheme("dark");
      } else if (footerRect.top <= 0 && footerRect.bottom >= 0) {
        setNavbarTheme("light");
      }

      // footer parallax logic
      if (stabilitySectionRect.bottom <= 350) {
        setIsFixed(true);
      } else if (stabilitySectionRect.bottom <= window.innerHeight + 10) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
      const thresholdPoint = isDesktop ? 400 : 300;
      if (compareBondRect.bottom <= thresholdPoint || isDefaultSectionInView) {
        setBackgroundColor("#000");
        setIsTransitionedLayout(true);
      } else {
        setBackgroundColor("#fff");
        setIsTransitionedLayout(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTransitionedLayout, isDefaultSectionInView]);
  return <>
			<MainWrapper $marginbottom={isFixed ? "0px" : "100vh"} data-sentry-element="MainWrapper" data-sentry-source-file="index.tsx">
				<InitialTransition data-sentry-element="InitialTransition" data-sentry-source-file="index.tsx" />
				<LandingNav navbarTheme={navbarTheme} data-sentry-element="LandingNav" data-sentry-source-file="index.tsx" />
				{isMobile && <SmartBanner downloadAppData={downloadAppData} />}

				<section ref={heroRef}>
					<Hero data-sentry-element="Hero" data-sentry-source-file="index.tsx" />
				</section>
				<section ref={diversifyRef}>
					<DiversifySection data-sentry-element="DiversifySection" data-sentry-source-file="index.tsx" />
				</section>
				<RiveSectionWrapper style={{
        backgroundColor
      }} data-sentry-element="RiveSectionWrapper" data-sentry-source-file="index.tsx">
					<section ref={compareBondRef}>
						<CompareBond isTransitionedLayout={isTransitionedLayout} data-sentry-element="CompareBond" data-sentry-source-file="index.tsx" />
					</section>
					<section ref={defaultSectionRef}>
						<Default data-sentry-element="Default" data-sentry-source-file="index.tsx" />
					</section>
				</RiveSectionWrapper>

				<section ref={repaymentSectionRef}>
					<Repayment data-sentry-element="Repayment" data-sentry-source-file="index.tsx" />
					<BackedBy data-sentry-element="BackedBy" data-sentry-source-file="index.tsx" />
					{isDesktop && <Divider />}

					<AppInfo strapiResponseData={downloadAppData} data-sentry-element="AppInfo" data-sentry-source-file="index.tsx" />
				</section>

				<section ref={ref}>
					<StabilityBanner data-sentry-element="StabilityBanner" data-sentry-source-file="index.tsx" />
				</section>

				{!isDesktop && !isFooterInView && <BottomStatCTA />}
			</MainWrapper>

			<StyledFooter ref={footerRef} $isfixed={isFixed} $topMargin={topMargin} data-sentry-element="StyledFooter" data-sentry-source-file="index.tsx">
				<HelpUs data-sentry-element="HelpUs" data-sentry-source-file="index.tsx" />
				<Footer data-sentry-element="Footer" data-sentry-source-file="index.tsx" />

				{!isDesktop && <BottomStatCTA />}
			</StyledFooter>
		</>;
};
export default LandingPage;
const InitialTransition = () => {
  const ctrls = useAnimation();
  const blackBox = {
    initial: {
      height: "100vh",
      bottom: 0
    },
    animate: {
      height: 0,
      top: -50,
      transition: {
        duration: 1,
        ease: [0.5, 0, 0.75, 0]
        // stiffness: 10,
        // delay: 1,
        // when: "afterChildren",
      }
    }
  };
  useEffect(() => {
    ctrls.start("animate");
  }, []);
  return <div style={{
    position: "absolute",
    display: "flex"
  }} data-sentry-component="InitialTransition" data-sentry-source-file="index.tsx">
			<InitialScreenContainer initial="initial" animate={ctrls} variants={blackBox} data-sentry-element="InitialScreenContainer" data-sentry-source-file="index.tsx">
				{/* WINT */}
			</InitialScreenContainer>
		</div>;
};
const InitialScreenContainer = styled(motion.div)`
	display: flex;
	position: relative;
	z-index: 999999;
	width: 100vw;
	background: black;
	color: white;
	justify-content: center;
	align-items: center;
	font-size: 56px;
	font-weight: 600;
`;
type MainWrapperTypes = {
  $marginbottom?: string;
};
type StyledFooterTypes = {
  $isfixed?: boolean;
  $topMargin: any;
};
const MainWrapper = styled.main<MainWrapperTypes>`
	margin-bottom: ${({
  $marginbottom
}) => $marginbottom};
`;
const StyledFooter = styled.footer<StyledFooterTypes>`
	position: ${({
  $isfixed
}) => !$isfixed ? "fixed" : "relative"};
	transition: all 0.3 ease;
	top: ${({
  $topMargin
}) => `${$topMargin}%`};
	bottom: 0;
	width: 100%;
	z-index: ${({
  $isfixed
}) => !$isfixed ? "-1" : "1"};
`;
const RiveSectionWrapper = styled.div`
	transition: background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;
const Divider = styled.div`
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0) 10.5%,
		rgba(0, 0, 0, 0.2) 49.53%,
		rgba(0, 0, 0, 0) 88.5%
	);
	height: 1px;
	width: 100%;
`;