import { styled, Interpolation, css } from "styled-components";
import { m, useAnimation, LazyMotion, domAnimation } from "framer-motion";
import { useEffect } from "react";
import { characterAnimation, wordAnimation } from "@features/Landing/constants/anims";
import { minDesktopCss, minLargeDesktopCss } from "@common/styles/media";
type AnimatedTitleTypes = {
  text: string;
  textColor?: string;
  fontSize?: string;
  styles?: Interpolation<React.CSSProperties>;
  fontWeight?: number;
  duration?: number;
  activeAfterIndex?: any;
};
const AnimatedTitle: React.FC<AnimatedTitleTypes> = ({
  text,
  textColor,
  fontSize,
  styles,
  fontWeight,
  duration,
  activeAfterIndex
}) => {
  const ctrls = useAnimation();
  useEffect(() => {
    ctrls.start("visible");
  }, []);
  return <LazyMotion features={domAnimation} data-sentry-element="LazyMotion" data-sentry-component="AnimatedTitle" data-sentry-source-file="index.tsx">
			<Title $styles={styles} color={textColor} role="heading" fontsize={fontSize} fontweight={fontWeight} data-sentry-element="Title" data-sentry-source-file="index.tsx">
				{text.split(" ").map((word: any, index: number) => <Word aria-hidden="true" key={index} initial="hidden" animate={ctrls} variants={wordAnimation} transition={{
        delayChildren: index * 0.1,
        staggerChildren: 0.02
      }} activeAfterIndex={index > activeAfterIndex}>
						{word.split("").map((character: any, idx: number) => {
          return <Character aria-hidden="true" key={idx} variants={characterAnimation(duration)}>
									{character}
								</Character>;
        })}{" "}
					</Word>)}
			</Title>
		</LazyMotion>;
};
export default AnimatedTitle;
type TitleTypes = {
  color?: string;
  fontsize?: string;
  $styles: Interpolation<React.CSSProperties>;
  fontweight?: number;
};
type WordTypes = {
  activeAfterIndex?: any;
};
const Title = styled(m.div)<TitleTypes>`
	color: ${props => props.color ? props.color : "#000"};
	font-family: var(--font-bricolage-grotesque);
	font-style: normal;
	overflow: hidden;
	letter-spacing: -0.1rem;
	/* transform: skew(20deg); */
	font-size: ${props => props.fontsize ? props.fontsize : "24px"};
	line-height: 100%;
	text-align: center;
	font-weight: 600;

	${minDesktopCss(css<TitleTypes>`
		font-size: ${props => props.fontsize || "5rem"};
		line-height: 100%;
		font-weight: ${props => props.fontweight || 600};
	`)}

	${minLargeDesktopCss(css<TitleTypes>`
		font-size: ${props => props.fontsize || "5.5rem"};
	`)}
`;
export const Character = styled(m.span)`
	display: inline-block;
`;
export const Word = styled(m.span)<WordTypes>`
	display: inline-block;
	margin-right: 0.25em;
	white-space: nowrap;
	color: ${({
  activeAfterIndex
}) => activeAfterIndex && "#BAAFA1"};
`;