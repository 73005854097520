"use client";

import { minDesktopCss } from "@common/styles/media";
import { ButtonDefaultText } from "@common/styles/typographies";
import Button from "@wint_design_system/molecules/Buttons/Button";
import styled, { css, useTheme } from "styled-components";
import { useAppContext } from "@common/context/AppContext";
import { useLandingContext } from "@features/Landing/contexts/index";
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS";
const StabilityBanner = () => {
  const {
    setShowLoginModal
  } = useAppContext();
  const theme = useTheme();
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    stabilityBanner
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="StabilityBanner" data-sentry-source-file="stabilityBanner.tsx">
			<Title dangerouslySetInnerHTML={{
      __html: stabilityBanner?.sectionTitle
    }} data-sentry-element="Title" data-sentry-source-file="stabilityBanner.tsx" />
			<SubText data-sentry-element="SubText" data-sentry-source-file="stabilityBanner.tsx">{stabilityBanner?.subTitle}</SubText>

			<Button clickId={LANDING_CLICK_IDS.get_started} type="primary" text={<ButtonDefaultText id={LANDING_CLICK_IDS.get_started} $webColor={theme.colors.text.button.onInverse} $mobColor={theme.colors.text.button.onInverse}>
						{stabilityBanner?.ctaText}
					</ButtonDefaultText>} themeInverse size="large" desktopStyles={{
      width: "217px",
      marginTop: "40px"
    }} mobileStyles={{
      marginTop: "32px"
    }} onClick={() => setShowLoginModal(true)} data-sentry-element="Button" data-sentry-source-file="stabilityBanner.tsx" />
		</Wrapper>;
};
export default StabilityBanner;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	background: #101010;
	padding: 88px 24px;

	${minDesktopCss(css`
		padding: 160px 24px;
	`)}
`;
const Title = styled.p`
	color: rgba(255, 255, 255, 0.4);
	text-align: center;
	font-family: var(--font-bricolage-grotesque);
	font-size: 2rem;
	font-style: normal;
	font-weight: 600;

	span {
		color: ${props => props.theme.colors.text.textInverse.primary};
	}

	${minDesktopCss(css`
		font-size: 56px;
		max-width: 800px;
	`)}
`;
const SubText = styled.p`
	color: ${props => props.theme.colors.text.tertiary};
	text-align: center;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-top: 8px;

	${minDesktopCss(css`
		font-size: 1rem;
	`)}
`;