import styled from "styled-components";
import { useRouter } from "next/navigation";
import { CIN, COMPLAINCE_ADVISORY_GUIDELINES_LINK, COMPLAINCE_IMPORTANT_INFO, COMPLAINCE_IMPORTANT_LINKS, DEPOSITORY_PARTICIPANT_ID, DEPOSITORY_REGISTRATION_NUMBER_ID, SEBI_BROKER_REGISTRATION_NUMBER, WINT_AUTORIZED_PERSONS, WINT_COMPLAINCE_EMAIL, WINT_COMPLAINCE_OFFICER_LAND_LINE, WINT_CONTACT_EMAIL } from "@common/constants/wint";
import { handleEmailUsClick } from "@common/utils/contact/index";
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS";
const FooterRFQComplaince = () => {
  const router = useRouter();
  return <FooterRFQComplainceWrap data-nosnippet="true" data-sentry-element="FooterRFQComplainceWrap" data-sentry-component="FooterRFQComplaince" data-sentry-source-file="FooterCompliance.tsx">
			<p>
				© 2024 Fourdegreewater Capital Private Limited. All rights reserved.
				CIN - {CIN}.
			</p>
			<p>
				Fourdegreewater Services Private Limited is the Stock broker entity
				operating in debt segment. It functions independently as an online bond
				platform provider in the debt segment.
			</p>
			<p>
				SEBI Stock Broker Registration No: {SEBI_BROKER_REGISTRATION_NUMBER} |
				DP ID: {DEPOSITORY_PARTICIPANT_ID} | DP Registration Number:{" "}
				{DEPOSITORY_REGISTRATION_NUMBER_ID}
			</p>
			<p>Exchange Membership No. : NSE: 90328</p>
			<p>
				Registered Office: 3rd Floor, 91 Springboard, The Pavilion, #175,
				Bannerghatta Rd, Dollar Layout, Near Rainbow Hospital, Phase 4, J. P.
				Nagar, Bengaluru, Karnataka 560076
			</p>
			<p>
				Corporate Office: 3rd Floor, 91 Springboard, The Pavilion, No - 175,
				Bannerghatta Rd, Dollar Layout, Near Rainbow Hospital, Phase 4, J. P.
				Nagar, Bengaluru, Karnataka 560076
			</p>
			<p>
				For any query / feedback / clarifications, email at{" "}
				<ClickableText onClick={handleEmailUsClick} data-sentry-element="ClickableText" data-sentry-source-file="FooterCompliance.tsx">
					{WINT_CONTACT_EMAIL}
				</ClickableText>
			</p>
			<p>
				In case of grievances for any of the services rendered by
				Fourdegreewater Services Private Limited, please write to{" "}
				<ClickableText onClick={handleEmailUsClick} data-sentry-element="ClickableText" data-sentry-source-file="FooterCompliance.tsx">
					{WINT_CONTACT_EMAIL}
				</ClickableText>{" "}
				(for NSE and BSE) or{" "}
				<ClickableText onClick={handleEmailUsClick} data-sentry-element="ClickableText" data-sentry-source-file="FooterCompliance.tsx">
					{WINT_CONTACT_EMAIL}
				</ClickableText>{" "}
				(for Depository Participant).
				<br />
				Please ensure that you carefully read the Risk Disclosure Document as
				prescribed by SEBI, our Terms of Use and Privacy Policy.
				<br />
				Compliance Officer: Ms. Vallari Dubey
				<br />
				Phone: {WINT_COMPLAINCE_OFFICER_LAND_LINE}
				<br />
				Email:{" "}
				<ClickableText onClick={() => window.open(`https://mail.google.com/mail/u/0/?fs=1&to=${WINT_COMPLAINCE_EMAIL}&tf=cm`)} data-sentry-element="ClickableText" data-sentry-source-file="FooterCompliance.tsx">
					{WINT_COMPLAINCE_EMAIL}
				</ClickableText>{" "}
				<br />
				To lodge your complaints using SEBI SCORES,{" "}
				<a href="https://scores.sebi.gov.in/scores-home/" target="_blank" rel="noopener noreferrer" id={LANDING_CLICK_IDS.sebi_scores_click_here}>
					click here
				</a>{" "}
				. Please see our Grievance Redressal Mechanism for detailed procedure in
				this regard. You can also lodge your complaints on the new{" "}
				<a href="https://smartodr.in/login" target="_blank" rel="noopener noreferrer" id={LANDING_CLICK_IDS.smart_online_dispute}>
					Smart Online Dispute Resolution
				</a>{" "}
				Platform.
			</p>
			<p>
				Mandatory details to register on{" "}
				<a href="https://scores.sebi.gov.in/scores-home/" target="_blank" rel="noopener noreferrer" id={LANDING_CLICK_IDS.sebi_scores}>
					SEBI SCORES
				</a>
				: Name, PAN, Address, Mobile Number, E-mail ID. Benefits: Effective
				Communication, Speedy redressal of the grievances.
			</p>
			<p>
				Details of Key Managerial Personnel and Authorized Persons:{" "}
				{WINT_AUTORIZED_PERSONS.map((personDetail, index) => {
        return <span key={index}>
							{personDetail.wintAuthorizedPersonName} -{" "}
							<ClickableText onClick={() => {
            window.open(`https://mail.google.com/mail/u/0/?fs=1&to=${personDetail.wintAuthorizedPersonEmail}&tf=cm`);
          }}>
								{personDetail.wintAuthorizedPersonEmail}
							</ClickableText>
							,{" "}
						</span>;
      })}
			</p>
			<p>
				Note: As a policy we do not give stock tips or recommendations and have
				not authorized anyone to give this on behalf of us. If you know anyone
				claiming to be a part of Wint Wealth / FDW Services / or our associate
				companies or partners and offering such services, please report us on{" "}
				<ClickableText onClick={handleEmailUsClick} data-sentry-element="ClickableText" data-sentry-source-file="FooterCompliance.tsx">
					{WINT_CONTACT_EMAIL}
				</ClickableText>
				. Important Information for Investors: To prevent unauthorized
				transactions in your trading / demat account, do not share your account
				details, credentials or any personal details with anyone. Keep your
				mobile number updated with your Stock Broker, Depository Participant and
				ensure that the same is registered with Stock Exchanges, Depository and
				KRAs. You will receive alerts and information on your registered mobile
				number / email for debit and other important transactions in your demat
				account directly from NSDL / Exchange on the same day. KYC is one time
				exercise while dealing in securities markets - once KYC is done through
				a SEBI registered intermediary (Stock Broker, DP, Mutual Fund, etc.),
				you need not undergo the same process again when you approach another
				intermediary. No need to issue cheques by investors while subscribing to
				an IPO. Just write the bank account number and sign in the application
				form to authorise your bank to make payment in case of allotment. No
				worries for refund as the money remains in the investor&apos;s account.
				This is issued in the interest of investors.
			</p>
			<p>
				Disclaimer: Investments in debt securities/municipal debt
				securities/securitised debt instruments are subject to risks, including
				delay and/ or default in payment. Read all the offer-related documents
				carefully
			</p>
			<p>
				Kindly, read the{" "}
				<a href={COMPLAINCE_ADVISORY_GUIDELINES_LINK} target="_blank" rel="noopener noreferrer" id={LANDING_CLICK_IDS.advisory_guidelines}>
					Advisory Guidelines
				</a>{" "}
				for investors as prescribed by the exchange with reference to their
				circular dated 27th August, 2021 regarding investor awareness and
				safeguarding client&apos;s assets.
			</p>
			<p>
				Download client registration documents (Rights & Obligations, Risk
				Disclosure Document, Do&apos;s & Don&apos;ts) in vernacular language:{" "}
				<a href="https://www.nseindia.com/trade/members-client-registration-documents" target="_blank" rel="noopener noreferrer" id={LANDING_CLICK_IDS.nse_single}>
					NSE
				</a>
			</p>
			<p>
				Link of accessing e-voting platform of NSDL:{" "}
				<a href="https://eservices.nsdl.com/" target="_blank" rel="noopener noreferrer">
					https://eservices.nsdl.com/
				</a>
			</p>
			<p>
				Important Links :
				<LinkGroup data-sentry-element="LinkGroup" data-sentry-source-file="FooterCompliance.tsx">
					{COMPLAINCE_IMPORTANT_LINKS.map((link, index) => {
          return <ClickableText key={index} onClick={() => {
            if (link.isExternalLink) {
              window.open(link.linkUrl);
            } else {
              router.push(link.linkUrl);
            }
          }} id={link?.clickId}>
								{link.linkLabel}
							</ClickableText>;
        })}
				</LinkGroup>
			</p>
			<ClickableText data-sentry-element="ClickableText" data-sentry-source-file="FooterCompliance.tsx">
				Important Information :
				<LinkGroup data-sentry-element="LinkGroup" data-sentry-source-file="FooterCompliance.tsx">
					{COMPLAINCE_IMPORTANT_INFO.map((link, index) => {
          return <ClickableText key={index} onClick={() => {
            if (link.isExternalLink) {
              window.open(link.linkUrl);
            } else {
              router.push(link.linkUrl);
            }
          }} id={link?.clickId}>
								{link.linkLabel}
							</ClickableText>;
        })}
				</LinkGroup>
			</ClickableText>
		</FooterRFQComplainceWrap>;
};
export default FooterRFQComplaince;
const FooterRFQComplainceWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	p {
		font-family: var(--font-inter);
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 160%;
		color: #7e7e7e;
	}
	a {
		font-weight: 500;
		color: #356935 !important;
	}

	@media (max-width: 500px) {
		padding: 1.5rem 0rem;
	}
`;
const LinkGroup = styled.span`
	& > * {
		margin-left: 5px;
		text-decoration: underline;
		&:not(:last-child) {
			border-right: 1px solid #6d6d6d;
			padding-right: 5px;
		}
	}
`;
const ClickableText = styled.span`
	font-weight: 500;
	color: #356935 !important;
	font-size: 10px;
	cursor: pointer;
`;