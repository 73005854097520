import { useAppContext } from "@common/context/AppContext";
import { usePlatformDataContext } from "@common/context/PlatformDataContext";
import { ButtonDefaultText } from "@common/styles/typographies";
import { countDigits, roundToNDigits } from "@common/utils/string/index";
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS";
import Button from "@wint_design_system/molecules/Buttons/Button";
import styled, { useTheme } from "styled-components";
const BottomStatCTA = () => {
  const theme = useTheme();
  const {
    setShowLoginModal
  } = useAppContext();
  const {
    platformData
  } = usePlatformDataContext();
  return <BottomContainer data-sentry-element="BottomContainer" data-sentry-component="BottomStatCTA" data-sentry-source-file="index.tsx">
			<StatContainer data-sentry-element="StatContainer" data-sentry-source-file="index.tsx">
				<StatText data-sentry-element="StatText" data-sentry-source-file="index.tsx">
					{" "}
					{`Join ${roundToNDigits(platformData?.totalInvestors, countDigits(platformData?.totalInvestors))}+`}
				</StatText>
				<StatText data-sentry-element="StatText" data-sentry-source-file="index.tsx">Investors</StatText>
			</StatContainer>
			<Button type="primary" clickId={LANDING_CLICK_IDS.signup_get_started} text={<ButtonDefaultText $webColor={theme.colors.text.button.onPrimary} $mobColor={theme.colors.text.button.onPrimary}>
						Get started
					</ButtonDefaultText>} size="large" onClick={() => setShowLoginModal(true)} data-sentry-element="Button" data-sentry-source-file="index.tsx" />{" "}
		</BottomContainer>;
};
export default BottomStatCTA;
const BottomContainer = styled.div`
	display: flex;
	position: fixed;
	left: 0;
	bottom: 0;
	border-top: 1px solid #f2f2f2;
	background: #fff;
	width: 100%;
	padding: 16px 24px;
`;
const StatContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 30%;
	justify-content: center;
`;
const StatText = styled.span`
	color: #7e7e7e;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
`;