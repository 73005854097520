"use client";

import { createContext, useContext, useMemo, useState } from "react";
type PlatformDataContextProviderProps = {
  children: React.ReactNode;
};
type ObjTypes = {
  value: number;
};
type DefaultContext = {
  platformData: any;
  setPlatformData: Function;
  platformDataObj: any;
  setPlatformDataObj: Function;
};
const defaultContext: DefaultContext = {
  platformData: {},
  setPlatformData: () => {},
  platformDataObj: {},
  setPlatformDataObj: () => {}
};
const PlatformDataContext = createContext<DefaultContext>(defaultContext);
export const usePlatformDataContext = () => useContext(PlatformDataContext);
export const PlatformDataProvider: React.FC<PlatformDataContextProviderProps> = ({
  children
}) => {
  const [platformData, setPlatformData] = useState<any>();
  const [platformDataObj, setPlatformDataObj] = useState<Record<string, ObjTypes>>();
  const value = useMemo(() => ({
    platformData,
    setPlatformData,
    platformDataObj,
    setPlatformDataObj
  }), [platformData, setPlatformData, platformDataObj, setPlatformDataObj]);
  return <PlatformDataContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="PlatformDataProvider" data-sentry-source-file="PlatformDataContext.tsx">
			{children}
		</PlatformDataContext.Provider>;
};