import styled from "styled-components";
type StarRatingTypes = {
  rating: Number;
};
const StarRating: React.FC<StarRatingTypes> = ({
  rating
}) => {
  const totalStars = 5;
  return <StarContainer data-sentry-element="StarContainer" data-sentry-component="StarRating" data-sentry-source-file="starRating.tsx">
      {[...Array(totalStars)].map((_, index) => {
      const starRating = Math.min(Math.max(Number(rating) - index, 0), 1);
      const uniqueId = `grad-${rating}-${index}`;
      return <svg key={index} width="36" height="36" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id={uniqueId}>
                <stop offset={`${starRating * 100}%`} stopColor="#FBC11C" />
                <stop offset={`${starRating * 100}%`} stopColor="lightgray" />
              </linearGradient>
            </defs>
            <path fill={`url(#${uniqueId})`} d="M12.8912 0.929955C13.1856 0.014394 14.481 0.0143902 14.7754 0.929951L17.1673 8.36906C17.2989 8.77826 17.6796 9.05573 18.1094 9.05573H25.8718C26.8279 9.05573 27.2281 10.2771 26.4574 10.843L20.1598 15.4666C19.8166 15.7186 19.673 16.1619 19.8034 16.5672L22.2046 24.0353C22.4983 24.9489 21.4504 25.7038 20.6769 25.1359L14.419 20.5414C14.0705 20.2856 13.5962 20.2856 13.2477 20.5414L6.98979 25.1359C6.21628 25.7038 5.16833 24.9489 5.46206 24.0353L7.86332 16.5672C7.99365 16.1619 7.85009 15.7186 7.50688 15.4666L1.20927 10.843C0.438565 10.2771 0.838796 9.05573 1.79492 9.05573H9.55724C9.98707 9.05573 10.3677 8.77826 10.4993 8.36906L12.8912 0.929955Z" />
          </svg>;
    })}
    </StarContainer>;
};
export default StarRating;
const StarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;