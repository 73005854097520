export const LANDING_CLICK_IDS = {
	hero_get_started: "NEW_LANDING_PAGE_V3-HERO-GET_STARTED",
	nav_bonds: "NAV_ASSETS_TAB",
	nav_fd: "NAV_FD",
	nav_login: "NAV_LOGIN",
	get_started: "NEW_LANDING_PAGE_V3-CTA-GET_STARTED",
	download_footer: "NEW_LANDING_PAGE_V3-CTA-DOWNLOAD_APP_FOOTER",
	twitter: "NEW_LANDING_PAGE_V3-ICON-X",
	youtube: "NEW_LANDING_PAGE_V3-ICON-YOUTUBE",
	linkedin: "NEW_LANDING_PAGE_V3-ICON-LINKEDIN",
	bonds_footer: "NEW_LANDING_PAGE_V3-TEXT-BONDS_FOOTER",
	fd_footer: "NEW_LANDING_PAGE_V3-TEXT-FD_FOOTER",
	sgb_footer: "NEW_LANDING_PAGE_V3-TEXT-SGB_FOOTER",
	wint_capital_footer: "NEW_LANDING_PAGE_V3-TEXT-WINTCAPITAL_FOOTER",
	our_story_footer: "NEW_LANDING_PAGE_V3-TEXT-OURSTORY_FOOTER",
	contact_us_footer: "NEW_LANDING_PAGE_V3-TEXT-CONTACTUS_FOOTER",
	blogs_footer: "NEW_LANDING_PAGE_V3-TEXT-BLOGS_FOOTER",
	tnc_footer: "NEW_LANDING_PAGE_V3-TEXT-TNC_FOOTER",
	privacy_footer: "NEW_LANDING_PAGE_V3-TEXT-PRIVACY_POLICY_FOOTER",
	investor_awareness_footer:
		"NEW_LANDING_PAGE_V3-TEXT-INVESTOR_AWARENESS_FOOTER",
	risk_disclosure_footer: "NEW_LANDING_PAGE_V3-TEXT-RISK_DISCLOSURES_FOOTER",
	signup_get_started: "NEW_LANDING_PAGE_V3-CTA-GET_STARTED_BONDS_SECTION",
	advisory_guidelines: "NEW_LANDING_PAGE_V3-URL-ADVISORY_GUIDELINES",
	nse_single: "NEW_LANDING_PAGE_V3-URL-NSE_SINGLE",
	nse_group: "NEW_LANDING_PAGE_V3-URL-NSE_GROUP",
	bse: "NEW_LANDING_PAGE_V3-URL-BSE",
	nsdl: "NEW_LANDING_PAGE_V3-URL-NSDL",
	sebi: "NEW_LANDING_PAGE_V3-URL-SEBI",
	scores: "NEW_LANDING_PAGE_V3-URL-SCORES",
	investor_charter_for_brokers:
		"NEW_LANDING_PAGE_V3-URL-INVESTOR_CHARTER_FOR_BROKERS",
	investor_charter_for_dp: "NEW_LANDING_PAGE_V3-URL-INVESTOR_CHARTER_FOR_DP",
	investor_attention: "NEW_LANDING_PAGE_V3-URL-INVESTOR_ATTENTION",
	risk_disclosure_disclaimers:
		"NEW_LANDING_PAGE_V3-URL-RISK_DISCLOSURES_DISCLAIMERS",
	account_opening_procedure:
		"NEW_LANDING_PAGE_V3-URL-ACCOUNT_OPENING_PROCEDURES",
	investor_awareness: "NEW_LANDING_PAGE_V3-URL-INVESTOR_AWARENESS",
	tnc: "NEW_LANDING_PAGE_V3-URL-TNC",
	privacy_policy: "NEW_LANDING_PAGE_V3-URL-PRIVACY_POLICY",
	pricing: "NEW_LANDING_PAGE_V3-URL-PRICING",
	sebi_scores: "NEW_LANDING_PAGE_V3-URL-SEBI_SCORES",
	sebi_scores_click_here: "NEW_LANDING_PAGE_V3-URL-SEBI_SCORES_CLICK_HERE",
	smart_online_dispute:
		"NEW_LANDING_PAGE_V3-URL-SMART_ONLINE_DISPUTE_RESOLUTION",
	smart_banner_id: "NEW_LANDING_PAGE_V3-SMART_BANNER-OPEN",

	diversify: {
		select: (productId: string | number) => {
			return `NEW_LANDING_PAGE_V3-ASSET_CARD_${productId}`
		},
	},
}
