import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS"

export const WINT_CONTACT_EMAIL = "hello@wintwealth.com"

export const WINT_COMPLAINCE_EMAIL = "compliance@wintwealth.com"

export const WINT_OFFICE_ADDRESS =
	"3rd Floor, 91 Springboard, The Pavilion, #175, Bannerghatta Rd, Dollar Layout, Near Rainbow Hospital, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560076"

export const WINT_CONTACT_NUMBER = "917904710557"

export const WINT_WHATSAPP_REACH_OUT_LINK = `https://api.whatsapp.com/send?phone=${WINT_CONTACT_NUMBER}&text=Hi%20Wint%20Wealth%20Team,%20I%20have%20a%20question%20and%20would%20like%20to%20get%20in%20touch%20with%20you.&source=&data=`

export const CIN = "U74110MH2020PTC338284"
export const SEBI_BROKER_REGISTRATION_NUMBER = "INZ000313632"
export const DEPOSITORY_PARTICIPANT_ID = "IN304633"
export const DEPOSITORY_REGISTRATION_NUMBER_ID = "INDP7592023"

export const COMPLAINCE_IMPORTANT_LINKS = [
	{
		linkLabel: "SEBI",
		linkUrl: "https://www.sebi.gov.in/",
		isExternalLink: true,
		clickId: LANDING_CLICK_IDS.sebi,
	},
	{
		linkLabel: "BSE",
		linkUrl: "https://www.bseindia.com/",
		isExternalLink: true,
		clickId: LANDING_CLICK_IDS.bse,
	},
	{
		linkLabel: "NSE",
		linkUrl: "https://www.nseindia.com/",
		isExternalLink: true,
		clickId: LANDING_CLICK_IDS.nse_group,
	},
	{
		linkLabel: "NSDL",
		linkUrl: "https://nsdl.co.in/",
		isExternalLink: true,
		clickId: LANDING_CLICK_IDS.nsdl,
	},
	{
		linkLabel: "SCORES",
		linkUrl: "https://scores.sebi.gov.in/scores-home/",
		isExternalLink: true,
		clickId: LANDING_CLICK_IDS.scores,
	},
	{
		linkLabel: "Investor Charter for Stock Brokers",
		linkUrl: "/investor-charter-stock-broker/",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.investor_charter_for_brokers,
	},
	{
		linkLabel: "Investor Charter for DP",
		linkUrl: "/investor-charter/",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.investor_charter_for_dp,
	},
	{
		linkLabel: "Investors' Attention",
		linkUrl: "/investors-attention/",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.investor_attention,
	},
]

export const COMPLAINCE_IMPORTANT_INFO = [
	{
		linkLabel: "Risk Disclosures & Disclaimers",
		linkUrl: "/risk-disclosures-disclaimers/",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.risk_disclosure_disclaimers,
	},
	{
		linkLabel: "Account Opening Procedure",
		linkUrl: "/account-opening-procedure/",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.account_opening_procedure,
	},
	{
		linkLabel: "Investor Awarenesss",
		linkUrl: "/investor-awareness/",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.investor_awareness,
	},
	{
		linkLabel: "Terms & Conditions",
		linkUrl: "/terms-and-conditions/",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.tnc,
	},
	{
		linkLabel: "Privacy Policy",
		linkUrl: "/investor-awareness?source=privacypolicy",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.privacy_policy,
	},
	{
		linkLabel: "Pricing",
		linkUrl: "/pricing",
		isExternalLink: false,
		clickId: LANDING_CLICK_IDS.pricing,
	},
]

export const COMPLAINCE_ADVISORY_GUIDELINES_LINK =
	"https://archives.nseindia.com/content/circulars/INSP49434.pdf"

export const WINT_COMPLAINCE_OFFICER_LAND_LINE = "+91-8861212363"
export const WINT_AUTORIZED_PERSONS = [
	{
		wintAuthorizedPersonName: "Anshul Gupta",
		wintAuthorizedPersonEmail: "director@wintwealth.com",
	},
	{
		wintAuthorizedPersonName: "Aditya Anand",
		wintAuthorizedPersonEmail: "ceo@wintwealth.com",
	},
	{
		wintAuthorizedPersonName: "Vallari Dubey",
		wintAuthorizedPersonEmail: WINT_COMPLAINCE_EMAIL,
	},
]
