import { useScroll } from "framer-motion"
import { useEffect, useState, useCallback } from "react"

/**
 *
 * @returns deprecated use from useScrollContext
 */
const useScrollDirection = () => {
	const [scrollDirection, setScrollDirection] = useState<string | undefined>("")
	const [lastScrollTop, setLastScrollTop] = useState(0)
	const { scrollY } = useScroll()

	const handleScroll = useCallback(() => {
		const currentScrollPosition = scrollY.get()
		/**
		 * To account for Safari's bounce effect, which causes a downward push when scrolling up from the top,
		 * ensure currentScrollPosition is greater than 0
		 */

		if (currentScrollPosition > lastScrollTop && currentScrollPosition > 0) {
			setScrollDirection("down")
		} else if (currentScrollPosition < lastScrollTop) {
			setScrollDirection("up")
		}

		setLastScrollTop(currentScrollPosition)
	}, [lastScrollTop, scrollY])

	useEffect(() => {
		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [scrollY, handleScroll])

	return { scrollDirection, currentScrollPosition: scrollY.get() }
}

export default useScrollDirection
