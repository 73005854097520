import { minDesktopCss } from "@common/styles/media";
import styled, { css, Interpolation } from "styled-components";
type SectionTitleProps = {
  $mobileStyles?: Interpolation<React.CSSProperties>;
  $desktopStyles?: Interpolation<React.CSSProperties>;
};
export const SectionTitle = styled.h2<SectionTitleProps>`
	color: ${({
  theme
}) => theme.colors.text.primary};
	font-family: var(--font-lora);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;

	${({
  $mobileStyles
}) => css`
		${$mobileStyles}
	`}

	${minDesktopCss(css<SectionTitleProps>`
		text-align: center;
		font-size: 56px;
		line-height: normal;
	`)}
`;