import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { minDesktopCss } from "@common/styles/media";
const PulseLineLoader = () => {
  const pulse = {
    start: {
      scaleX: 0,
      opacity: 0
    },
    end: {
      scaleX: [0, 0.5, 1, 1.5],
      opacity: [0, 0.5, 1, 1],
      transition: {
        duration: 1.2,
        ease: "easeInOut",
        delay: 1.2
      }
    }
  };
  return <Container data-sentry-element="Container" data-sentry-component="PulseLineLoader" data-sentry-source-file="pulseLoader.tsx">
			<Line variants={pulse} initial="start" animate="end" data-sentry-element="Line" data-sentry-source-file="pulseLoader.tsx" />
		</Container>;
};
const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 500px;
	overflow: hidden;
	margin-top: 1.5rem;

	${minDesktopCss(css`
		margin-top: 1rem;
		width: 700px;
	`)}
`;
const Line = styled(motion.div)`
	width: 100%;
	height: 1px;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 32.73%,
		rgba(255, 255, 255, 0.2) 49.53%,
		rgba(255, 255, 255, 0) 67.15%
	);
	transform-origin: center;
`;
export default PulseLineLoader;