import { useAppContext } from "@common/context/AppContext";
import { minDesktopCss } from "@common/styles/media";
import AccordionItem, { Accordion } from "@wint_design_system/molecules/Accordion/AccordionItem";
import styled, { css } from "styled-components";
import FooterInfo from "./FooterInfo";
import FooterRFQComplaince from "./FooterCompliance";
import { FOOTER_MENU } from "./constant";
const Footer = () => {
  const {
    isDesktop,
    setShowLoginModal,
    userState
  } = useAppContext();
  const isFooterLinkHref = (item: any) => {
    if (userState?.isLoggedIn || !item.showSignupModal) {
      return item?.link;
    }
    return undefined;
  };
  const convertedFooterContent = (value: any) => {
    return <FooterGroup data-sentry-element="FooterGroup" data-sentry-component="convertedFooterContent" data-sentry-source-file="index.tsx">
				{value.map((item: {
        name: string;
        link: string;
        clickId: string;
        showSignupModal: boolean;
      }, index: number) => <StyledLink id={item.clickId} href={isFooterLinkHref(item)} onClick={() => {
        if (item.showSignupModal && !userState.isLoggedIn) {
          setShowLoginModal(true);
        }
      }} key={index}>
							<FooterItem id={item.clickId}> {item.name} </FooterItem>
						</StyledLink>)}
			</FooterGroup>;
  };
  return <SectionWrapper data-sentry-element="SectionWrapper" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
			<Wrapper data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
				<FooterInfo data-sentry-element="FooterInfo" data-sentry-source-file="index.tsx" />
				<Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
					{isDesktop ? FOOTER_MENU.map((menu, index) => <FooterGroup key={index}>
								<GroupTitle> {menu.title} </GroupTitle>
								{menu.items.map((item, idx) => <StyledLink id={item.clickId} key={idx} href={isFooterLinkHref(item)} onClick={() => {
            if (item.showSignupModal && !userState.isLoggedIn) {
              setShowLoginModal(true);
            }
          }}>
										<FooterItem id={item.clickId}>{item.name}</FooterItem>
									</StyledLink>)}
							</FooterGroup>) : <Accordion>
							{FOOTER_MENU.map((menu, index) => <AccordionItemWrapper key={index} $isFirstItem={index === 0}>
									<AccordionItem title={<span style={{
              color: "#7e7e7e"
            }}>{menu.title}</span>} content={convertedFooterContent(menu.items)} />
								</AccordionItemWrapper>)}
						</Accordion>}
				</Container>

				{isDesktop && <Divider />}

				<DisclaimerContainer data-sentry-element="DisclaimerContainer" data-sentry-source-file="index.tsx">
					<FooterRFQComplaince data-sentry-element="FooterRFQComplaince" data-sentry-source-file="index.tsx" />
				</DisclaimerContainer>
			</Wrapper>
		</SectionWrapper>;
};
export default Footer;
const SectionWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fafafa;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 48px 24px;

	${minDesktopCss(css`
		padding: 48px 200px;
		max-width: 1500px;
	`)}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;

	${minDesktopCss(css`
		flex-direction: row;
		gap: 56px;
	`)}
`;
const FooterGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-width: 256px;
`;
const GroupTitle = styled.p`
	color: var(--color-TEXT-tertiary, #7e7e7e);
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;
const FooterItem = styled.p`
	color: var(--color-TEXT-primary, #000);
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
const DisclaimerContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 1rem;
`;
const AccordionItemWrapper = styled.div<{
  $isFirstItem: boolean;
}>`
	margin-top: ${({
  $isFirstItem
}) => $isFirstItem ? "0px" : "24px"};
`;
const StyledLink = styled.a`
	text-decoration: none;
	color: #000000;
	cursor: pointer;
`;
const Divider = styled.div`
	height: 1px;
	background: #f2f2f2;
	margin: 2px 0px 0px 0;

	${minDesktopCss(css`
		margin: 92px 0px 56px 0px;
	`)}
`;