import { getImagesCdnUrl } from "@common/utils/env"

export const AUTH_MITC_CONSENT =
	"https://davf9otgz7zc8.cloudfront.net/images//MOST_IMPORTANT_TERMS_AND_CONDITIONS_MITC_43151b71bc.pdf?updated_at=2023-12-21T13:09:19.214Z"

export const AUTH_ACTIONS = {
	login: "LOGIN",
	signUp: "SIGN_UP",
	forgotPin: "FORGOT_PIN",
}

export const AUTHENTICATION_STEPS = {
	login: "login",
	smsOtp: "smsOtp",
	enterPin: "enterPin",
	basicDetails: "basicDetails",
	emailOtp: "emailOtp",
	setPin: "setPin",
	forgotPinSmsOtp: "forgotPinSmsOtp",
	forgotPinEmailOtp: "forgotPinEmailOtp",
	onBoardingSurveys: "onBoardingSurveys",
}

export const ONBOARDING_SURVEYS = {
	organicTraffic: "organic_traffic_survey",
	investmentChoice: "asset_preference",
}

export const SIGNUP_FORM_INPUT_DETAILS = [
	{
		name: "name",
		value: "",
		error: "",
		type: "name",
		label: "Full name",
	},
	{
		name: "email",
		value: "",
		error: "",
		type: "email",
		label: "Email address",
	},
]

export const AUTHENTICATION_CAROUSEL_SLIDES = [
	{
		id: 1,
		heading: "0 defaults",
		subtextPrefix: "Join",
		subtextSuffix: "people by investing in our carefully curated products",
		subtextMetric: "totalInvestors",
		imageMWeb: {
			data: {
				id: 871,
				attributes: {
					url: `${getImagesCdnUrl()}/login/mwebSlide1.svg`,
					updatedAt: "2024-09-18T07:24:39.648Z",
				},
			},
		},
		imageDWeb: {
			data: {
				id: 872,
				attributes: {
					url: `${getImagesCdnUrl()}/login/webSlide1.svg`,
					updatedAt: "2024-09-18T07:24:48.891Z",
				},
			},
		},
	},
	{
		id: 2,
		heading: "We invest with you",
		subtextPrefix:
			"We invest 2% of the total bond size in every bond we bring on the platform",
		subtextSuffix: null,
		subtextMetric: null,
		imageMWeb: {
			data: {
				id: 873,
				attributes: {
					url: `${getImagesCdnUrl()}/login/mwebSlide2.svg`,
					updatedAt: "2024-09-18T07:25:03.297Z",
				},
			},
		},
		imageDWeb: {
			data: {
				id: 874,
				attributes: {
					url: `${getImagesCdnUrl()}/login/webSlide2.svg`,
					updatedAt: "2024-09-18T07:25:15.719Z",
				},
			},
		},
	},
	{
		id: 3,
		heading: "100% repayments on time",
		subtextPrefix: null,
		subtextSuffix: "has been successfully repaid, always on time!",
		subtextMetric: "totalRepaidBonds",
		imageMWeb: {
			data: {
				id: 875,
				attributes: {
					url: `${getImagesCdnUrl()}/login/mwebSlide3.svg`,
					updatedAt: "2024-09-18T07:25:27.424Z",
				},
			},
		},
		imageDWeb: {
			data: {
				id: 876,
				attributes: {
					url: `${getImagesCdnUrl()}/login/webSlide3.svg`,
					updatedAt: "2024-09-18T07:25:36.943Z",
				},
			},
		},
	},
]

export const AUTH_ERROR_KEYS = {
	phoneNumber: "",
	smsOtp: "",
	emailOtp: "",
	enterPin: "",
	confirmPin: "",
	forgotPinSmsOtp: "",
	forgotPinEmailOtp: "",
}

export const AUTH_TRIGGER_PARAMS = {
	initiateLogin: "initiateLogin",
	initiateFdLogin: "initiateFdLogin",
}
