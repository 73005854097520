"use client"

import { ReactNode, useEffect, useRef } from "react"
import { createPortal } from "react-dom"

type PortalProps = {
	children: ReactNode
}

const Portal: React.FC<PortalProps> = ({ children }) => {
	const portalElementRef = useRef<HTMLElement | null>(null)

	useEffect(() => {
		portalElementRef.current = document?.getElementById("portal")
	}, [])

	return portalElementRef.current
		? createPortal(children, portalElementRef.current)
		: null
}

export default Portal
